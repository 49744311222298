import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';
import { EventEmitter } from 'events';

export const isLoggedIn = (token) => {
    if(!token)
        return false;
    const decoded = jwt.decode(token)
    if(!decoded)
        return false;
    return dayjs(decoded.exp * 1000).isAfter(dayjs());
}

export const TokenEvents = new EventEmitter();

export const timeUntilExpiration = (token) => {
    if(!token)
        return 0;
    const decoded = jwt.decode(token);
    if(!decoded)
        return 0;
    return dayjs(decoded.exp * 1000).diff(dayjs());
}
