import keys from 'lodash/keys';

export const activeConsoles = {
    input: true,
    qpi: false,
    flowPage: false,
    graph: false,
    nodeDetails: false,
    keydown: false,
}

export const setupConsoles = function () {
    keys(activeConsoles).forEach(newConsole => console[newConsole] = (
        activeConsoles[newConsole] ?
            ((...params) => console.debug(...params))
            :
            ((...params) => { })
    ));
}
