import FlipBookObject from "../types/FlipBook";

export interface FlipBookListState {
  searchText: string;
  list: FlipBookObject[];
}

var flipBookListReducer: FlipBookListState = {
  searchText: '',
  list: []
};

export default flipBookListReducer;
