import React from 'react';
import injectSheet, { WithStylesProps } from 'react-jss';
import { Theme } from '../Theming';
import { Css } from '../types/CssTypes';

const imp = '!important';
const styles: any = (theme: Theme): Css => ({
    "@global": {
        "::selection": {
            background: theme.accent.inverted.$, /* WebKit/Blink Browsers */
            color: theme.accent.inverted.text
        },
        "::-moz-selection": {
            background: theme.accent.inverted.$, /* Gecko Browsers */
            color: theme.accent.inverted.text
        },
        ".reactour__helper": {
            backgroundColor: `${theme.background.main.$} ${imp}`,
            color: `${theme.text.default.$} ${imp}`,
            "& > button": {
                color: theme.accent.inverted.$,
                "&:hover": {
                    color: theme.accent.inverted.hover
                },
                "&:active": {
                    color: theme.accent.inverted.active
                },
            }
        },
        "#___reactour div[data-tour-elem=\"controls\"]": {
            "& > button": {
                color: theme.accent.inverted.$,
                "&:hover": {
                    color: theme.accent.inverted.hover
                },
                "&:active": {
                    color: theme.accent.inverted.active
                },
            },

            "& > button:disabled": {
                color: theme.text.disabled.$,
                "&:hover": {
                    color: theme.text.disabled.hover
                },
                "&:active": {
                    color: theme.text.disabled.active
                }
            }
        },
        "div:not(.public-DraftEditorPlaceholder-inner, .sun-editor, .se-btn-module, .se-wrapper-inner, .tooltip)": {
            //color: theme.text.default.$
        },
        "span:not(.key-main, .key-sub, .txt)": {
            //color: theme.text.default.$
        },
        "strong": {
            color: theme.text.bold.$
        },
        "em": {
            color: theme.text.default.$
        },
        "pre": {
            whiteSpace: "pre-wrap"
        },
        "label": {
            color: theme.text.label.$
        },
        "a": {
            color: theme.accent.button.text
        },
        "::-webkit-input-placeholder": { /* Edge */
            color: theme.text.placeholder.$
        },
        ":-ms-input-placeholder": { /* Internet Explorer 10-11 */
            color: theme.text.placeholder.$
        },
        "::placeholder": {
            color: theme.text.placeholder.$
        },
        "h1, h2, h3, h4, h5, h6": {
            color: theme.text.header.$
        },
        ".success": {
            color: theme.text.success.$,
            fill: theme.text.success.$
        },
        ".error": {
            color: theme.text.error.$,
            fill: theme.text.error.$
        },
        ".danger": {
            color: theme.text.error.$,
            fill: theme.text.error.$
        },
        ".bg-success": {
            background: theme.background.success.$
        },
        ".bg-error": {
            background: theme.background.error.$
        }
    }
})

interface AppContainerProps extends WithStylesProps<typeof styles> { }

class AppContainer extends React.Component<AppContainerProps> {
    render() {
        const { children } = this.props;
        return (
            <div className="app">{children}</div>
        );
    }
}

export default injectSheet(styles)(AppContainer);