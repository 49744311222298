import { StorageContainerDetails } from "../types/Storage";

export type StorageGlobal = {
    list: StorageContainerDetails[],
}

var storageReducer : StorageGlobal = {
    list: []
}

export default storageReducer;