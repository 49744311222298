import { IconName } from '@fortawesome/pro-light-svg-icons';
import { BannerIconName } from '../components/Banner';
import { arrayClone } from "../helpers/ArrayHelpers";

type ActiveBannerID = 0 | 1 | 2;

type BannerColor = 'red' | 'yellow' | 'green' | 'accent' | 'secondary' | 'tertiary';

interface BannerClickAction {
    dismiss?: boolean;
    newBannerObject?: BannerObject;
}

interface BannerAdditionalButton {
    icon?: BannerIconName;
    hint?: string;
    action?: (e: any) => void | BannerClickAction;
}

export interface BannerObject {
    icon?: BannerIconName; //Default = Font Awesome Info Circle
    title: string;
    message?: string;
    color?: BannerColor; //Default = accent

    timeout?: number;

    option?: BannerAdditionalButton; // Displays as a small clickable button
    dismissable?: boolean; //Default = true - Display/Do Not Display small `X` to close banner

    action?: (e: any) => void | BannerClickAction;
}

interface BannerReducerObject {
    pendingBanners: BannerObject[];
    activeBanners: {
        0?: BannerObject;
        1?: BannerObject;
        2?: BannerObject;
    },
    activeBannerId: ActiveBannerID;
}

var banners: BannerReducerObject = {
    pendingBanners: [],
    activeBanners: {
        0: undefined,
        1: undefined,
        2: undefined
    },
    activeBannerId: 0
}

export const showBanner = (global, dispatch, bannerObj: BannerObject) => {
    //Add to pending
    const newPendingBanners = arrayClone(global.banners.pendingBanners || []);
    newPendingBanners.push(bannerObj);

    return ({
        banners: {
            ...global.banners,
            pendingBanners: newPendingBanners
        }
    });
}

export const clearBanners = (global, dispatch) => {
    return ({
        banners: {
            pendingBanners: [],
            activeBanners: {
                0: undefined,
                1: undefined,
                2: undefined
            },
            activeBannerId: 0
        }
    })
}

export default banners;