import React from 'reactn';
import { arrayClone } from '../helpers/ArrayHelpers';
import Banner from './Banner';

interface BannerManagerProps {

}

//NOTE: Fade out recently dismissed (removed from global.banners.activeBanners) and somehow keep it the for a brief period

class BannerManager extends React.Component<BannerManagerProps, any> {

    state = {
        one: -1,
        two: -1,
        three: -1
    }

    KillBanner(index) {
        this.setGlobal({
            banners: {
                ...this.global.banners,
                activeBanners: {
                    ...this.global.banners.activeBanners,
                    [index]: undefined
                }
            }
        })
    }

    componentDidUpdate() {
        const { activeBanners, pendingBanners } = this.global.banners;
        if (pendingBanners.length > 0) {
            var availableIndex = -1;
            for (let index = 0; index < 3; index++) {
                if (!activeBanners[index]) {
                    availableIndex = index;
                    break;
                }
            }

            if (availableIndex >= 0) {

                var newPendingBanners = arrayClone(pendingBanners);
                var newBannerToShow = newPendingBanners.shift();

                switch (availableIndex) {
                    case 0:
                        this.setGlobal({
                            banners: {
                                ...this.global.banners,
                                pendingBanners: newPendingBanners,
                                activeBanners: {
                                    ...this.global.banners.activeBanners,
                                    [availableIndex]: newBannerToShow
                                }
                            }
                        },
                            () => this.setState({
                                one: 0,
                                two: this.state.two === 0 ? 1 : this.state.two,
                                three: this.state.three >= 0 ? (this.state.two >= 0 ? 2 : 1) : this.state.three
                            }));
                        break;
                    case 1:
                        this.setGlobal({
                            banners: {
                                ...this.global.banners,
                                pendingBanners: newPendingBanners,
                                activeBanners: {
                                    ...this.global.banners.activeBanners,
                                    [availableIndex]: newBannerToShow
                                }
                            }
                        },
                            () => this.setState({
                                one: 0,
                                two: 1,
                                three: this.state.three === 1 ? 2 : this.state.three
                            }));
                        break;
                    case 2:
                        this.setGlobal({
                            banners: {
                                ...this.global.banners,
                                pendingBanners: newPendingBanners,
                                activeBanners: {
                                    ...this.global.banners.activeBanners,
                                    [availableIndex]: newBannerToShow
                                }
                            }
                        },
                            () => this.setState({
                                one: 0,
                                two: 1,
                                three: 2
                            }));
                        break;

                    default:
                        break;
                }
            }
        }
    }

    render() {

        const { activeBanners } = this.global.banners;

        const bannerOne = activeBanners[0];
        const bannerTwo = activeBanners[1];
        const bannerThree = activeBanners[2];

        return (
            <>
                <Banner
                    {...bannerOne}
                    activeIndex={this.state.one}
                    onDismiss={() => {
                        if (this.state.one >= 0) {
                            this.setState({ one: -1, two: this.state.two - 1, three: this.state.three - 1 },
                                () => {
                                    setTimeout(() => {
                                        this.KillBanner(0);
                                    }, 350);
                                });
                        }

                    }}
                />
                <Banner
                    {...bannerTwo}
                    activeIndex={this.state.two}
                    onDismiss={() => {
                        if (this.state.two >= 0) {
                            this.setState({ two: -1, three: this.state.three - 1 },
                                () => {
                                    setTimeout(() => {
                                        this.KillBanner(1);
                                    }, 350);
                                });
                        }
                    }}
                />
                <Banner
                    {...bannerThree}
                    activeIndex={this.state.three}
                    onDismiss={() => {
                        if (this.state.three >= 0) {
                            this.setState({ three: -1 },
                                () => {
                                    setTimeout(() => {
                                        this.KillBanner(2);
                                    }, 350);
                                });
                        }
                    }}
                />
            </>
        );
    }
}

export default BannerManager;