import React from 'react';
import { Theme } from '../Theming';
import '../styles/Tooltip.scss';
import injectSheet, { WithStylesProps } from 'react-jss';

const styles = (theme: Theme) => ({
    tooltip: {
        userSelect: 'none',
        color: theme.text.tooltip.$,
        background: theme.background.tooltip.$,
        transition: theme.transition.quick,
        boxShadow: theme.shadow.tooltip.$
    },
    arrow: {
        borderRightColor: (props: TooltipProps) => props.position === 'right' ? theme.background.tooltip.$ : 'transparent',
        borderLeftColor: (props: TooltipProps) => props.position === 'left' ? theme.background.tooltip.$ : 'transparent',
        borderTopColor: (props: TooltipProps) => props.position === 'top' ? theme.background.tooltip.$ : 'transparent',
        borderBottomColor: (props: TooltipProps) => props.position === 'bottom' ? theme.background.tooltip.$ : 'transparent',
        transition: theme.transition.quick,
        //boxShadow: theme.shadow.tooltip
    }
})

interface TooltipProps extends WithStylesProps<typeof styles> {
    position: "top" | "left" | "right" | "bottom";
}

class Tooltip extends React.Component<TooltipProps, { opacity: number }> {
    state = {
        opacity: 0
    }

    componentDidMount() {
        this.setState({ opacity: 1 })
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={"tooltip " + classes.tooltip} style={{ opacity: this.state.opacity }}>
                {this.props.children}
                <div className={"tooltip-arrow " + classes.arrow} />
            </div>
        );
    }
}

export default injectSheet(styles)(Tooltip);