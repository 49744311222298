import React, { Suspense, lazy, createRef } from 'reactn';
import { ThemeProvider } from 'react-jss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
  Prompt
} from 'react-router-dom';
import ReactHintFactory from 'react-hint';
import * as Sentry from '@sentry/react';
import { faSignOut, faCog, faLayerGroup, faFileInvoice, faTrafficLightGo, faMoon, faSun, faQuestion, faFlagCheckered, faPuzzlePiece, faLayerPlus, faToolbox, faFilePlus, faBook, faStream, faEllipsisHAlt, faUserAlt, faUsers, faLock, faBoxOpen, faFileAlt } from '@fortawesome/pro-light-svg-icons';
import Tour from 'reactour';

import {
  themes,
  setTheme,
  Theme,
  ThemeEvents
} from './Theming';
import { WebEvents } from './WebRequest';
import Localize from './Localize';
import PageNameManager from './PageNameManager';

import './styles/App.scss';
import './styles/rc-switch.scss';

import { isLoggedIn, TokenEvents, timeUntilExpiration } from './helpers/AuthHelpers';
import Humanizer from './helpers/Humanizer';

import flowApi from './api/flowApi';
import './api/Qpi';
import Qpi from './api/Qpi';

import TourGuides from './constants/TourGuides';

import { FlowTypeObject } from './types/FlowTypes';

import Sidebar, { SidebarSegment } from './components/Sidebar';
import Main from './components/Main';
import IconButton from './components/IconButton';
import BannerManager from './components/BannerManager';
import Tooltip from './components/Tooltip';
import Loader from './components/animations/Loader';
import PageLoader from './components/PageLoader';
import AppContainer from './components/AppContainer';

const TopMenu = lazy(() => import('./components/TopMenu'));

const Flow = lazy(() => import('./pages/Flow'));
const FlowList = lazy(() => import('./pages/FlowList'));
const FlipBookList = lazy(() => import('./pages/FlipBookList'));
const Triggers = lazy(() => import('./pages/Triggers'));
const Extensions = lazy(() => import('./pages/Extensions'));
const Settings = lazy(() => import('./pages/Settings'));
const CreateForm = lazy(() => import('./pages/CreateForm'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Login = lazy(() => import('./pages/Login'));
const Book = lazy(() => import('./pages/FormView'));
const AddTrigger = lazy(() => import('./pages/TriggerPages/AddTrigger'));
const FlowType = lazy(() => import('./pages/FlowType'));

Sentry.init({
  dsn: "https://ed8f41f83221438ca00f670b05bab8a6@o216304.ingest.sentry.io/1551215",
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.NODE_ENV === "development" ? false : true
});

if (process.env.REACT_APP_ENV !== "live")
  console.warn("You are currently in the Build Mode [" + (process.env.REACT_APP_ENV || "none") + "]")

const ReactHint = ReactHintFactory(React);

interface AppState {
  logoLoading: boolean;
  currentTour: string;
  tourOpen: boolean;
}

class App extends React.Component<any, AppState> {
  router: any;
  constructor(props) {
    super();
    this.router = createRef<Router>();
    this.state = {
      logoLoading: false,
      currentTour: 'Intro',
      tourOpen: false
    };

    this.theme = themes[this.global.config.general.theme](this.global.config.general.accentColour, this.global.config.general.colourMethod);

    this.FetchUserConfiguration = this.FetchUserConfiguration.bind(this);
    this.FetchEssentials = this.FetchEssentials.bind(this);

    this.changeLightDark = this.changeLightDark.bind(this);
    this.onTourClick = this.onTourClick.bind(this);    

  }

  theme: Theme;
  tourRef;
  tokenExpireTimer;

  webRequestActive() {
    this.setState({ logoLoading: true });
  }

  webRequestDone = () => {
    this.setState({ logoLoading: false });
  }

  async FetchUserConfiguration() {
    flowApi.get("/config")
      .then(async ({ data }) => {
        this.setGlobal({ config: data })
      })
      .catch(() => {

      })
  }

  FetchEssentials = async () => {
    console.log("Fetching Essentials");
    flowApi.flowType.getAll()
      .then(async ({ data }) => {
        const flowTypesArr = data.map(ft => ({
          ...ft,
          dataJs: JSON.parse(ft.data),
          data: JSON.parse(ft.data) //TODO: REMOVE and Refactor Any Currently Using this instead of dataJs
        })) as FlowTypeObject[];

        this.setGlobal((currentGlobal) => ({
          flowTypes: {
            ...currentGlobal.flowTypes,
            list: flowTypesArr
          },
          pageLoad: {
            ...currentGlobal.pageLoad,
            flowDefinitionsLoaded: true
          }
        }));
      })
      .catch((e) => {
        console.error(e);
      });

    Qpi.extensions.select
      .query()
      .then(async (data) => {
        //TODO: IMPORTANT: Clear each extensions data value (Containing api/code object). Only pull on demand when permitted for current user
        this.setGlobal((currentGlobal) => ({
          extensions: {
            ...currentGlobal.extensions,
            list: data
          },
          pageLoad: {
            ...currentGlobal.pageLoad,
            extensionsLoaded: true
          }
        })
        );
      })
      .catch((e) => {
        console.error(e);
      });

    Qpi.triggers.select
      .where("user_id", "==", this.global.auth?.user?.id)
      .include("flow", "name") //Limit Data Transferred - i.e. `Flow Data` which could be huge depending on flow size and complexity
      .groupBy("id")
      .query()
      .then(async (data) => {
        this.setGlobal((currentGlobal) => ({
          triggers: {
            ...currentGlobal.triggers,
            list: data
          },
          pageLoad: {
            ...currentGlobal.pageLoad,
            triggersLoaded: true
          }
        }), () => {
          this.global.auth?.organizations?.forEach(
            org =>
              Qpi.triggers.select
                .where("user_id", "==", org.account_user)
                .include("flow", "name")
                .groupBy("id")
                .query()
                .then(async (data) => {
                  this.setGlobal({
                    triggers: {
                      ...this.global.triggers,
                      list: this.global.triggers.list.concat(data)
                    }
                  })
                })
                .catch((e) => {
                  console.error(e);
                })
          );
        });
      })
      .catch((e) => {
        console.error(e);
      });
  
    console.log("Location", this.props);

    if (this?.props?.location?.pathname.indexOf("/viewbook/") === -1 && this.global.flipBookList.list.length === 0) {
    Qpi.flowbook.select
      .where("user_id", "==", this.global.auth?.user?.id)
      .query()
      .then(async (data) => {
        this.setGlobal((currentGlobal) => ({
          flipBookList: {
            ...currentGlobal.flipBookList,
            list: data
          },
          pageLoad: {
            ...currentGlobal.pageLoad,
            flipBooksLoaded: true
          }
        }), () => {
          this.global.auth?.organizations?.forEach(
            org =>
              Qpi.flowbook.select
                .where("user_id", "==", org.account_user)
                .query()
                .then(async (data) => {
                  this.setGlobal({
                    flipBookList: {
                      ...this.global.flipBookList,
                      list: this.global.flipBookList.list.concat(data)
                    }
                  })
                })
                .catch((e) => {
                  console.error(e);
                })
          );
        });
      })
      .catch((e) => {
        console.error(e);
      });
    }

    flowApi.storage.listContainers()
      .then(({ data }) => {
        this.setGlobal((currentGlobal) => ({
          storage: {
            ...currentGlobal.storage,
            list: data
          },
          pageLoad: {
            ...currentGlobal.pageLoad,
            storageContainersLoaded: true
          }
        })
        )
      })
      .catch((e) => {
        console.error(e);
      })

    // Qpi.flows
    // .select
    // .where("dateCreated", "greaterThanOrEquals", moment().subtract(2, "week"))
    // .query()
    // .then((data) => console.log(data));

    flowApi.get("/flow/get")
      .then(async ({ data }) => {
        if (!data.error && data) {
          this.setGlobal((currentGlobal) => ({
            flowList: {
              ...currentGlobal.flowList,
              list: data.list
            },
            pageLoad: {
              ...currentGlobal.pageLoad,
              flowsLoaded: true
            }
          })
          );
        }
      })
      .catch((e) => {
        console.error(e);
      })

    Qpi.organization_user_invitations
      .select
      .where("email", "==", this.global.auth?.user?.email)
      .include("organization")
      .query()
      .then((data) => {
        if (data && data.length > 0) {
          this.dispatch.showBanner({
            title: `Pending Invitation (${data.length})`,
            action: () => {
              this.router.history.push('/settings/profile');
              return { dismiss: true }
            },
            message: `You have (${data.length}) invitations pending. Click here to view.`,
            dismissable: true,
            icon: "envelope-open",
            timeout: 3000
          })
            .then(() => this.setGlobal({ invitations: { pendingInvitations: data } }));
        }
      })
      .catch((e) => {
        console.error(e);
      })
  }

  componentDidCatch(error, info) {
    console.warn(error);
  }

  themeChange(newTheme, newAccent, newColourMethod) {
    this.theme = themes[newTheme](newAccent, newColourMethod);
    this.forceUpdate();
  }

  tokenExpired() {
    this.dispatch.logout();
  }

  tokenRenewed(newToken) {
    console.log("History"
    ,this?.router?.history?.location?.pathname);
    if (this?.router?.history?.location?.pathname.indexOf("/viewbook/") === -1) {
      this.FetchEssentials();
    }
    this.tokenExpireTimer = setTimeout(() => TokenEvents.emit("expired"), timeUntilExpiration(newToken));
  }

  componentDidMount() {
    ThemeEvents.addListener('change', this.themeChange.bind(this))
    WebEvents.addListener('send', this.webRequestActive.bind(this));
    WebEvents.addListener('done', this.webRequestDone.bind(this));
    TokenEvents.addListener("expired", this.tokenExpired.bind(this));
    TokenEvents.addListener("renew", this.tokenRenewed.bind(this));

    //this.FetchUserConfiguration();
    //this.ConfigureFirestore();

    if (this.global.auth.token && isLoggedIn(this.global.auth.token)) {
      console.log("History"
      ,this?.router?.history?.location?.pathname);
      if (this?.router?.history?.location?.pathname.indexOf("/viewbook/") === -1) {
        this.FetchEssentials();
      }
      this.tokenExpireTimer = setTimeout(() => TokenEvents.emit("expired"), timeUntilExpiration(this.global.auth.token));
    }
  }

  componentWillUnmount() {
    WebEvents.removeAllListeners();
    TokenEvents.removeAllListeners();
    ThemeEvents.removeAllListeners();
    clearTimeout(this.tokenExpireTimer);
  }

  changeLightDark() {
    this.setGlobal({
      config: {
        ...this.global.config,
        general: {
          ...this.global.config.general,
          theme: this.global.config.general.theme === 'light' ? 'dark' : 'light'
        }
      }
    },
      () => setTheme(this.global.config.general.theme, this.global.config.general.accentColour, this.global.config.general.colourMethod)
    );
  }

  onTourClick () {
    if (this.tourRef) {
      this.tourRef.gotoStep(0);
    }
    this.router.history.push('/flow/example')
    this.setState({ tourOpen: true })
  }

  render() {
    var buttonSize = { width: 36, height: 36 };
    const theme = this.theme;

    return (
      <ThemeProvider theme={theme}>
        <Router ref={(ref) => this.router = ref}>
          <Suspense fallback={null}>
            <Route path="/pricing" component={TopMenu} />
          </Suspense>

          <Suspense fallback={<PageLoader />}>
            <Switch>

              <Route path="/:page(login|register|reset)/:oauth?" component={Login} />

              {/* TODO: Remove this Line and have backend handle correct routing/caching */}
              <Route path="/viewbook/:flipbook_id?" component={Book} />

              <Route path="/pricing" component={Pricing} />

              <Route render={() =>
                <AppContainer>
                  <Route render={(routerProps) => (
                    <Sidebar>
                      <SidebarSegment position="top">
                        {
                          [
                            <Loader key="loader" logo loading={this.state.logoLoading} style={{ paddingBottom: 4 }} />,
                            <hr
                              style={{
                                borderBottom: `1px solid ${theme.misc.separatorColor}`,
                                width: '100%',
                                borderTop: 'none',
                                marginBottom: 18,
                                boxShadow: theme.shadow.separatorDown.$
                              }}
                            />,
                            /* TODO: Submenus for Main item -> i.e. Flows -> Build, List */
                            // <IconButton
                            //   hint={Humanizer.capitalizeAll(Localize("Build Flow"))}
                            //   component={Link}
                            //   to="/flow"
                            //   variant="rounded-corners"
                            //   icon={faLayerPlus}
                            //   color="sidebar"
                            //   size={buttonSize}
                            //   iconSize="lg"
                            // />,
                            // <IconButton
                            //   hint={Humanizer.capitalizeAll(Localize("Flow List"))}
                            //   component={Link}
                            //   to="/flowList"
                            //   variant="rounded-corners"
                            //   icon={faLayerGroup}
                            //   color="sidebar"
                            //   size={buttonSize}
                            //   iconSize="lg"
                            // />,
                            // <IconButton
                            //   hint={Humanizer.capitalizeAll("Create Flow Book")}
                            //   component={Link}
                            //   to="/flowbook"
                            //   variant="rounded-corners"
                            //   icon={faFilePlus}
                            //   color="sidebar"
                            //   size={buttonSize}
                            //   iconSize="lg"
                            // />,
                            // <IconButton
                            //   hint={Humanizer.capitalizeAll("Flow Books")}
                            //   component={Link}
                            //   to="/flowbooklist"
                            //   variant="rounded-corners"
                            //   icon={faFileInvoice}
                            //   color="sidebar"
                            //   size={buttonSize}
                            //   iconSize="lg"
                            // />,
                            {
                              //hint: "Flows",
                              to: "/flowList",
                              icon: faStream,
                              width: 250,
                              height: 220,
                              children: [
                                <h3 key="flowbook-title">Flows</h3>,
                                {
                                  hint: "Create Flow",
                                  to: "/flow",
                                  icon: faLayerPlus
                                },
                                {
                                  hint: "List Flows",
                                  to: "/flowList",
                                  icon: faLayerGroup
                                }
                              ]
                            },
                            {
                              //hint: "Flow Books",
                              to: "/flowbooklist",
                              icon: faBook,
                              width: 250,
                              height: 220,
                              children: [
                                <h3 key="flowbook-title">Flow Books</h3>,
                                {
                                  hint: "Create Flow Book",
                                  to: "/flowbook",
                                  icon: faFilePlus
                                },
                                {
                                  hint: "List Flow Books",
                                  to: "/flowbooklist",
                                  icon: faFileInvoice
                                }
                              ]
                            },
                            this.global.auth?.user?.admin ?
                            <IconButton
                              hint={Humanizer.capitalizeAll(Localize("Extensions"))}
                              component={Link}
                              to="/extensions"
                              variant="rounded-corners"
                              icon={faPuzzlePiece}
                              color="sidebar"
                              size={buttonSize}
                              iconSize="lg"
                            /> : <></>,
                            this.global.auth?.user?.admin ?
                            <IconButton
                              hint={Humanizer.capitalizeAll(Localize("Trigger Type"))}
                              component={Link}
                              to="/triggerType"
                              variant="rounded-corners"
                              icon={faToolbox}
                              color="sidebar"
                              size={buttonSize}
                              iconSize="lg"
                            /> : <></>, 
                            <IconButton
                              hint={Humanizer.capitalizeAll(Localize("Triggers"))}
                              component={Link}
                              to="/triggers"
                              variant="rounded-corners"
                              icon={faTrafficLightGo}
                              color="sidebar"
                              size={buttonSize}
                              iconSize="lg"
                            />,
                            // <IconButton
                            //   hint={Humanizer.capitalizeAll(Localize("Settings"))}
                            //   component={Link}
                            //   to="/settings"
                            //   variant="rounded-corners"
                            //   icon={faCog}
                            //   color="sidebar"
                            //   size={buttonSize}
                            //   iconSize="lg"
                            // />,
                            {
                              //hint: Humanizer.capitalizeAll(Localize("Settings")),
                              to: "/settings",
                              icon: faCog,
                              width: 250,
                              height: 400,
                              children: [
                                <h3 key="settings-title">Settings</h3>,
                                {
                                  hint: "General",
                                  to: "/settings/general",
                                  icon: faEllipsisHAlt
                                },
                                {
                                  hint: "Profile",
                                  to: "/settings/profile",
                                  icon: faUserAlt
                                },
                                {
                                  hint: "Organizations",
                                  to: "/settings/organizations",
                                  icon: faUsers
                                },
                                {
                                  hint: "Api Keys",
                                  to: "/settings/api_keys",
                                  icon: faLock
                                },
                                {
                                  hint: "Storage",
                                  to: "/settings/storage",
                                  icon: faBoxOpen
                                }
                              ]
                            }
                          ]
                        }
                      </SidebarSegment>
                      <SidebarSegment position="bottom">
                        <IconButton
                          hint={"FAQ Documentation - Coming Soon"}
                          variant="round"
                          icon={faFileAlt}
                          size={{ width: 20, height: 20 }}
                          color="sidebar"
                          iconSize="sm"
                        />
                        <IconButton
                          hint={Localize("How does it work?")}
                          variant="round"
                          icon={faQuestion}
                          size={{ width: 20, height: 20 }}
                          color="sidebar"
                          iconSize="sm"
                          onPress={this.onTourClick}
                        />
                        <IconButton
                          hint={Humanizer.capitalizeAll(Localize(this.global.config.general.theme === "light" ? "Night Mode" : "Day Mode"))}
                          onPress={this.changeLightDark}
                          variant="round"
                          iconSize="lg"
                          icon={this.global.config.general.theme === "light" ? faMoon : faSun}
                          color="sidebar"
                        />
                        <IconButton
                          hint={Humanizer.capitalizeAll(Localize("Sign Out"))}
                          variant="rounded-corners"
                          icon={faSignOut}
                          color="sidebar"
                          //iconSize="lg"
                          onPress={() => this.dispatch.logout()}
                        />
                      </SidebarSegment>
                    </Sidebar>
                  )}
                  />
                  <Main>
                    <Suspense fallback={<PageLoader />}>
                      <Route path="/flow/:active_flow?" component={this.global.pageLoad.extensionsLoaded ? Flow : PageLoader} />
                    </Suspense>

                    <Suspense fallback={<PageLoader />}>
                      <Route path="/flowList" component={FlowList} />
                    </Suspense>
                    <Suspense fallback={<PageLoader />}>
                      <Route path="/triggers" component={Triggers} exact />
                    </Suspense>

                    <Suspense fallback={<PageLoader />}>
                      <Route path="/extensions" component={Extensions} exact />
                    </Suspense>
                    <Suspense fallback={<PageLoader />}>
                      <Route path="/triggers/add" component={AddTrigger} exact />
                    </Suspense>
                    <Suspense fallback={<PageLoader />}>
                      <Route path="/triggerType" component={FlowType} exact />
                    </Suspense>

                    <Suspense fallback={<PageLoader />}>
                      <Route path="/settings/:option(storage|account|defaults)?" component={Settings} />
                    </Suspense>

                    <Suspense fallback={<PageLoader />}>
                      <Route path="/flowbook/:flipbook_id?" component={this.global.pageLoad.extensionsLoaded ? CreateForm : PageLoader} />
                    </Suspense>

                    <Suspense fallback={<PageLoader />}>
                      <Route path="/flowbooklist" component={FlipBookList} />
                    </Suspense>

                    <Route path="/" render={() => <Redirect path="/" to="/flowList" exact />} exact />{/* Default Screen: Flow List */}
                    <Prompt when={this.global.sessionVars.unsavedWork} message={Localize("You have unsaved changes on your flow, are you sure you want to continue?")} />
                    {
                      this.global.auth.token && isLoggedIn(this.global.auth.token) ?
                        null
                        // (
                        //   this.global.auth.user.admin ?
                        //     null
                        //     :
                        //     <Redirect to="/login?admin_only=true" />
                        // )
                        :
                        <Redirect to="/login" />
                    }
                  </Main>
                </AppContainer>
              } />
            </Switch>
          </Suspense>
          <div style={{ position: 'absolute' }}>
            <ReactHint
              autoPosition
              events={{ click: false, focus: true, hover: true }}
              position="right"
              onRenderContent={(target, content) => {
                return <Tooltip position="right">{content}</Tooltip>;
              }}
            />
            <BannerManager />
            {this.global.redirect && <Redirect to={this.global.redirect} />}
            <Route render={(routerProps) => (
              // <Suspense fallback={<div>Loading...</div>}>
              <Tour
                accentColor={this.global.config.general.accentColour}
                ref={tourRef => this.tourRef = tourRef}
                steps={TourGuides[this.state.currentTour]()}
                badgeContent={(current, total) => TourGuides[this.state.currentTour]()[current - 1].badge}
                rounded={8}
                isOpen={this.state.tourOpen}
                showNavigationNumber={false}
                onRequestClose={() => {
                  routerProps.history.replace('/');
                  this.setState({ tourOpen: false })
                }}
                lastStepNextButton={
                  <IconButton onPress={() => {
                    routerProps.history.replace('/');
                    this.setState({ tourOpen: false })
                  }}
                    icon={faFlagCheckered}
                    iconPosition="left"
                    iconSize="lg"
                    style={{ fontSize: '1.2em' }}
                  >
                    {Localize("Finish")}
                  </IconButton>
                }
              />
              // </Suspense>
            )}
            />
            <Route
              render={(routerProps) => (
                <PageNameManager location={routerProps.location.pathname} />
              )}
            />
          </div>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
