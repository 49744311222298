import { EventEmitter } from "events";

const WebEvents = new EventEmitter();

export { 
    WebEvents
};

export default function WebRequest () {
    var originalOpenXMLHttpRequest = (window as any).XMLHttpRequest.prototype.open;
    var originalSendXMLHttpRequest = (window as any).XMLHttpRequest.prototype.send;
    
    function openReplacement(method, url, async, user, password) {
        this._url = url;
        return originalOpenXMLHttpRequest.apply(this, arguments);
    }
    
    function sendReplacement(data) {
        if (this.onreadystatechange) {
            this._onreadystatechange = this.onreadystatechange;
        }
    
        //console.log('Request sent');
        
        WebEvents.emit('send');
    
        this.onreadystatechange = onReadyStateChangeReplacement;
        return originalSendXMLHttpRequest.apply(this, arguments);
    }
    
    function onReadyStateChangeReplacement() {
    
        //console.log('Ready State: ', this.readyState);

        //0 - UNSENT
        //1 - OPENED
        //2 - HEADERS_RECEIVED
        //3 - LOADING
        //4 - DONE

        if(this.readyState >= 0 && this.readyState < 4) {
            WebEvents.emit('loading');
        } else {
            WebEvents.emit('done');
        }

        if (this._onreadystatechange) {
            try { return this._onreadystatechange.apply(this, arguments); }
            catch(e) { console.error(e); }
        }
    }
    
    (window as any).XMLHttpRequest.prototype.open = openReplacement;
    (window as any).XMLHttpRequest.prototype.send = sendReplacement;
};