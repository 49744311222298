import * as Sentry from '@sentry/react';
import UserObject from "../types/User";
import Qpi from "../api/Qpi";
import flowApi from "../api/flowApi";
import { TokenEvents, isLoggedIn } from "../helpers/AuthHelpers";
import { SetLocale } from "../Localize";
import OrganizationObject from '../types/Organization';

export interface AuthGlobal {
    user?: UserObject;
    organizations?: OrganizationObject[];
    token?: string;
    locale: string;
}

try {
    var currentUser = (localStorage.getItem("auth.user") && JSON.parse(localStorage.getItem("auth.user")));
    var currentOrganizations = (localStorage.getItem("auth.organizations") && JSON.parse(localStorage.getItem("auth.organizations")));
} catch { }

var authReducer: AuthGlobal = {
    user: currentUser || null,
    organizations: currentOrganizations || null,
    token: localStorage.getItem("auth.token") || null,
    locale: localStorage.getItem("auth.locale") || 'en'
}

if (authReducer.token && isLoggedIn(authReducer.token)) {
    Qpi.forAll((table) => table.setAuthorizationHeader(`Bearer ${authReducer.token}`));
    flowApi.configure({ headers: { "Authorization": `Bearer ${authReducer.token}` } });

    if (currentUser)
        Sentry.setUser({
            "email": currentUser.email,
            "username": currentUser.username,
            "id": currentUser.id,
            "locale": currentUser.locale || "en"
        });
}

export const updateUser = (user) => {
    localStorage.setItem("auth.user", JSON.stringify(user));
}

export const login = (global, dispatch, user, token, organizations) => {
    Qpi.forAll((table) => table.setAuthorizationHeader(`Bearer ${token}`));
    flowApi.configure({ headers: { "Authorization": `Bearer ${token}` } });
    localStorage.setItem("auth.user", JSON.stringify(user));
    localStorage.setItem("auth.token", token);
    localStorage.setItem("auth.locale", user.locale);
    localStorage.setItem("auth.organizations", JSON.stringify(organizations));

    SetLocale(user.locale);
    TokenEvents.emit("renew", token);
    try {
        Sentry.setUser({
            "email": user.email,
            "username": user.username,
            "id": user.id,
            "locale": user.locale || "en"
        });
    } catch { }
    return ({
        auth: {
            user,
            token,
            organizations,
            locale: user.locale || "en"
        }
    })
};

export const logout = (global, dispatch) => {
    Qpi.forAll((table) => table.removeHeader("Authorization"));
    flowApi.configure({ headers: {} });
    localStorage.removeItem("auth.user");
    localStorage.removeItem("auth.token");
    localStorage.removeItem("auth.organizations");
    TokenEvents.emit("cleared");
    try { Sentry.setUser(null); } catch { }
    return ({
        auth: {
            user: null,
            token: null,
            organizations: null
        }
    });
};

export default authReducer;