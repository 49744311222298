import TriggerObject from "../types/Trigger";

export type TriggerGlobal = {
    list: TriggerObject[],
}

var triggerReducer : TriggerGlobal = {
    list: []
}

export default triggerReducer;