import React, { MouseEventHandler } from 'react';
import '../styles/Sidebar.scss';
import { Theme } from '../Theming';
import injectSheet, { WithStylesProps } from 'react-jss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PortalWithState } from 'react-portal';
import IconButton from './IconButton';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

interface DebouncedFunc<T extends (...args: any[]) => any> {
    /**
     * Call the original function, but applying the debounce rules.
     *
     * If the debounced function can be run immediately, this calls it and returns its return
     * value.
     *
     * Otherwise, it returns the return value of the last invokation, or undefined if the debounced
     * function was not invoked yet.
     */
    (...args: Parameters<T>): ReturnType<T> | undefined;

    /**
     * Throw away any pending invokation of the debounced function.
     */
    cancel(): void;

    /**
     * If there is a pending invokation of the debounced function, invoke it immediately and return
     * its return value.
     *
     * Otherwise, return the value from the last invokation, or undefined if the debounced function
     * was never invoked.
     */
    flush(): ReturnType<T> | undefined;
}

interface SidebarItemDetails {
    hint: string;
    to: string;
    icon: IconProp;
}

interface SidebarMainItemDetails {
    hint?: string;
    to: string;
    icon: IconProp;
    width: number;
    height: number;

    children?: (SidebarItemDetails | JSX.Element)[];
}

type BtnRef = React.RefObject<any> | ((instance: any) => void);
type MouseEventHandlerWithIdentifier = (identifier: string, event: React.MouseEvent<Element, MouseEvent>) => void;

interface SidebarItemProps extends SidebarItemDetails {
    primary?: boolean;
    buttonRef?: BtnRef;
    onMouseEnter?: MouseEventHandler | MouseEventHandlerWithIdentifier;
    onMouseLeave?: MouseEventHandler | MouseEventHandlerWithIdentifier;
}

const sidebarButtonSize = { width: 36, height: 36 };

class SidebarItem extends React.PureComponent<SidebarItemProps> {
    render() {
        const { primary, buttonRef, hint, to, icon, onMouseEnter, onMouseLeave } = this.props;
        if (primary)
            return (
                <IconButton
                    linkRef={buttonRef}
                    key={to}
                    hint={hint}
                    component={Link}
                    to={to}
                    variant="rounded-corners"
                    icon={icon}
                    color="sidebar"
                    size={sidebarButtonSize}
                    iconSize="lg"
                    onMouseLeave={onMouseLeave}
                    onMouseEnter={onMouseEnter}
                />
            );
        else
            return (
                <IconButton
                    linkRef={buttonRef}
                    key={to}
                    //hint={hint}
                    component={Link}
                    to={to}
                    variant="rounded-corners"
                    icon={icon}
                    color="sidebar"
                    size={sidebarButtonSize}
                    iconSize="lg"
                    onMouseLeave={onMouseLeave}
                    onMouseEnter={onMouseEnter}
                    style={{ width: "calc(100% - 24px)" }}
                >
                    {hint}
                </IconButton>
            );
    }
}

const subMenuStyles = (theme: Theme) => ({
    linkContainer: {
        zIndex: 11,
        position: "fixed",
        minWidth: 150,
        minHeight: 150,
        background: theme.background.sidebarContext.$,
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 10,
        padding: 10,
        boxShadow: theme.shadow.flowOverlay.$
    }
})

interface SidebarMenuProps extends WithStylesProps<typeof subMenuStyles>, SidebarMainItemDetails {
    identifier: string;

    setOpenPortal: (identifier: string, openPortal: (event?) => void) => void;
    setClosePortal: (identifier: string, closePortal: (event?) => void) => void;

    onMouseEnter?: MouseEventHandlerWithIdentifier;
    onMouseLeave?: MouseEventHandlerWithIdentifier;
}

interface SidebarMenuState {
    x: number;
    y: number;
}

class SidebarMenu extends React.Component<SidebarMenuProps, SidebarMenuState> {
    constructor(props: SidebarMenuProps) {
        super(props);

        this.state = {
            x: 0,
            y: 0
        }

        this.setupButtonRef = this.setupButtonRef.bind(this);

        this.onResize = this.onResize.bind(this);

        this.onMouseIn = this.onMouseIn.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    buttonRef: HTMLAnchorElement;

    setupButtonRef(btnRef: HTMLAnchorElement) {
        this.buttonRef = btnRef;
    }

    onResize() {
        //console.log(`Offset: (${this.buttonRef.offsetLeft}, ${this.buttonRef.offsetTop})`)
        //console.log(`Client: (${this.buttonRef.clientLeft}, ${this.buttonRef.clientTop})`)
        this.setState({
            x: this.buttonRef.offsetLeft + this.buttonRef.offsetWidth + 23,
            y: this.buttonRef.offsetTop + 6
        })
    }

    onMouseIn(event: React.MouseEvent<Element, MouseEvent>) {
        this.props.onMouseEnter(this.props.identifier, event);
    }

    onMouseOut(event: React.MouseEvent<Element, MouseEvent>) {
        this.props.onMouseLeave(this.props.identifier, event);
    }

    render() {
        const { classes, identifier, hint, to, icon, width, height, setOpenPortal, setClosePortal, children } = this.props;
        const { x, y } = this.state;

        return (
            <>
                <PortalWithState
                    closeOnOutsideClick
                    closeOnEsc
                >
                    {({ openPortal, closePortal, isOpen, portal }) => {
                        setOpenPortal(identifier, openPortal);
                        setClosePortal(identifier, closePortal);
                        return (
                            <React.Fragment>
                                <SidebarItem
                                    buttonRef={this.setupButtonRef}
                                    hint={hint}
                                    to={to}
                                    icon={icon}
                                    onMouseEnter={this.onMouseIn}
                                    onMouseLeave={this.onMouseOut}
                                    primary
                                />
                                {portal(
                                    <div style={{ left: x, top: y, width, height }} onMouseEnter={this.onMouseIn} onMouseOver={this.onMouseIn} onMouseOut={this.onMouseOut} onMouseLeave={this.onMouseOut} className={classes.linkContainer + " flow-builder-sidebar-context"}>
                                        {
                                            children?.map(item => {
                                                if (React.isValidElement(item)) {
                                                    return item;
                                                } else {
                                                    item = item as SidebarItemDetails;
                                                    return <SidebarItem
                                                        key={item.to}
                                                        hint={item.hint}
                                                        to={item.to}
                                                        icon={item.icon}
                                                    />
                                                }
                                            })
                                        }
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    }}
                </PortalWithState>
            </>
        );
    }
}

const StyledSidebarMenu = injectSheet(subMenuStyles)(SidebarMenu);

interface SidebarSegmentProps {
    position: "top" | "bottom"
    children: (SidebarMainItemDetails | JSX.Element)[]
}

interface SidebarSegmentState {
    currentHover: string;
}

export class SidebarSegment extends React.Component<SidebarSegmentProps, SidebarSegmentState> {

    constructor(props: SidebarSegmentProps) {
        super(props);

        this.state = {
            currentHover: ""
        }

        this.onSetOpenPortal = this.onSetOpenPortal.bind(this);
        this.onSetClosePortal = this.onSetClosePortal.bind(this);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    pendingDebounces: { [identifier: string]: DebouncedFunc<() => void> } = {};

    openPortals: { [identifier: string]: () => void } = {};
    closePortals: { [identifier: string]: () => void } = {};

    onSetOpenPortal(identifier: string, openPortal: () => void) {
        this.openPortals[identifier] = openPortal;
    }

    onSetClosePortal(identifier: string, closePortal: () => void) {
        this.closePortals[identifier] = closePortal;
    }

    onMouseEnter(identifier: string, event: React.MouseEvent<Element, MouseEvent>) {
        if (identifier !== this.state.currentHover && this.state.currentHover) {
            this.closePortals[this.state.currentHover]();
        } else {
            if (this.pendingDebounces[identifier]) {
                this.pendingDebounces[identifier].cancel();
                delete this.pendingDebounces[identifier];
            }
        }

        this.openPortals[identifier]();
        this.setState({ currentHover: identifier });

        //console.log("Entering Hover for " + identifier);
    }

    onMouseLeave(identifier: string, event: React.MouseEvent<Element, MouseEvent>) {
        if (this.pendingDebounces[identifier]) {
            this.pendingDebounces[identifier].cancel();
            delete this.pendingDebounces[identifier];
        }

        this.pendingDebounces[identifier] = debounce(() => {
            //console.log("Closing " + identifier);
            this.closePortals[identifier]();
            delete this.pendingDebounces[identifier];

        }, 200);

        this.pendingDebounces[identifier]();

        //console.log("Exiting Hover for " + identifier);
    }

    render() {
        const { position, children } = this.props;
        return (
            <div className={position}>
                {children.map(menuItem => {
                    if (React.isValidElement(menuItem)) {
                        return menuItem;
                    } else {
                        return <StyledSidebarMenu
                            {...(menuItem as any)}
                            identifier={(menuItem as any).to}
                            key={(menuItem as any).to}

                            setOpenPortal={this.onSetOpenPortal}
                            setClosePortal={this.onSetClosePortal}

                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                    }
                })}
            </div>
        );
    }
}

const styles = (theme: Theme) => ({
    "@global": {
        ".flow-builder-sidebar": {
            
        },
        ".flow-builder-sidebar-context": {
            "& h1, & h2, & h3, & h4, & h5, & h6": {
                background: theme.text.sidebar.$,
                color: theme.background.sidebar.$
            }
        }
    },
    sidebar: {
        color: theme.text.sidebar.$,
        background: theme.background.sidebar.$,
        boxShadow: theme.shadow.default.$
    }
})

interface SidebarProps extends WithStylesProps<typeof styles> { }

class Sidebar extends React.Component<SidebarProps> {
    render() {

        const { classes } = this.props;

        return (
            <div className={"flow-builder-sidebar " + classes.sidebar}>{this.props.children}</div>
        );
    }
}

export default injectSheet(styles)(Sidebar);
