import React from 'react';
import injectSheet, { WithStylesProps } from 'react-jss';
import { Theme } from '../../Theming.jsx';
import { ifCss } from '../../helpers/CssHelpers';
const styles = (theme: Theme) => ({
  svgComponent: {
    "& > line, & > path, & > rect": {
      stroke: (props) => props.logo ? theme.logo.color : theme.logo.animations.front
    },
    "& > line.background, & > path.background, & > rect.background": {
      stroke: theme.logo.animations.back || 'transparent'
    },
    overflow: 'overlay'
  }
});

interface LoaderProps extends WithStylesProps<typeof styles> {
  color?: string;
  size?: 'tiny' | 'small' | 'large' | 'logo' | number;
  style?: any;
  className?: any;
  loading?: boolean;
  logo?: boolean;
}

class Loader extends React.Component<LoaderProps> {

  render() {
    const tiny = 32;
    const small = 48;
    const large = 100;
    const logo_size = 54;

    var size;

    if (typeof this.props.size === 'number') {
      size = this.props.size;
    } else if (this.props.size === 'tiny') {
      size = tiny;
    } else if (this.props.size === 'large') {
      size = large;
    } else if (this.props.size === 'logo' || (!this.props.size && this.props.logo)) {
      size = logo_size;
    } else {
      size = small;
    }

    const { classes, loading, logo } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 68 73"
        style={{ ...{ height: size, width: size }, ...this.props.style }}
        className={`logo-loader ${classes.svgComponent}${ifCss(this.props.className, this.props.className)}`}
      >
        {/* Background */}
        {/* <path d="M14,14H26A12.5,12.5,0,1,1,14,1.5Z" className={`logo-loader-top-left background`} />
        <path d="M54,59H41.5A12.5,12.5,0,1,1,54,71.5Z" className={`logo-loader-bottom-right background`} />
        <path d="M54,14V26.5A12.5,12.5,0,1,1,66.5,14Z" className={`logo-loader-top-right background`} />
        <path d="M14,59V46.5A12.5,12.5,0,1,1,1.5,59Z" className={`logo-loader-bottom-left background`} />
        <rect className={`logo-loader-left-vertical background`} />
        <rect className={`logo-loader-right-vertical background`} />
        <rect className={`logo-loader-top-horizontal background`} />
        <rect className={`logo-loader-bottom-horizontal background`} /> */}

        {/* Main */}
        <path d="M14,14H26A12.5,12.5,0,1,1,14,1.5Z" className={`logo-loader-top-left${ifCss(loading || !logo, ' animate')}`} />
        <path d="M54,59H41.5A12.5,12.5,0,1,1,54,71.5Z" className={`logo-loader-bottom-right${ifCss(loading || !logo, ' animate')}`} />
        <path d="M54,14V26.5A12.5,12.5,0,1,1,66.5,14Z" className={`logo-loader-top-right${ifCss(loading || !logo, ' animate')}`} />
        <path d="M14,59V46.5A12.5,12.5,0,1,1,1.5,59Z" className={`logo-loader-bottom-left${ifCss(loading || !logo, ' animate')}`} />
        <rect className={`logo-loader-left-vertical${ifCss(loading || !logo, ' animate')}`} />
        <rect className={`logo-loader-right-vertical${ifCss(loading || !logo, ' animate')}`} />
        <rect className={`logo-loader-top-horizontal${ifCss(loading || !logo, ' animate')}`} />
        <rect className={`logo-loader-bottom-horizontal${ifCss(loading || !logo, ' animate')}`} />
      </svg>
    );
  }
}

export default injectSheet(styles)(Loader);
