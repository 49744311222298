import FlowObject from "../types/Flow";

export interface FlowListState {
  searchText: string;
  list: FlowObject[];
}

var flowListReducer: FlowListState = {
  searchText: '',
  list: []
};

export default flowListReducer;
