import React from 'react';
import '../styles/Main.scss';
import { Theme } from '../Theming';
import injectSheet from 'react-jss';
import { Css, CssInjector } from '../types/CssTypes';

const styles: CssInjector = (theme: Theme): Css => ({
    main: {
        background: theme.background.main.$,
        justifyContent: 'flex-start'
    }
})

class Main extends React.Component<{ classes?: { [name: string]: string }, location?: any;  }> {
    render() {
        const { classes } = this.props;
        return (
            <div className={"flow-builder-main " + classes.main}>{this.props.children}</div>
        );
    }
}

export default injectSheet(styles)(Main);