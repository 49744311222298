import { FlowTypeObject } from "../types/FlowTypes";

export interface FlowTypesGlobal {
    list: FlowTypeObject[]
}

var flowTypeReducer: FlowTypesGlobal = {
    list: []
}

export default flowTypeReducer;