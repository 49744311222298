import { FlowBackgroundNames } from "../components/backgrounds";
import { ThemeOption, ColourMethod } from "../Theming";

export interface UserConfig {
    general: {
        snapToGridByDefault?: boolean;
        autoHideNodeSelection?: boolean;
        theme?: ThemeOption;
        flowBackground?: FlowBackgroundNames;
        accentColour?: string;
        colourMethod?: ColourMethod;

        [configName: string]: any
    };
    storage: {
        storageBins?: { name: string, columns: { name: string, type: "string" | "number" | "bool" | "datetime" }[] }[];

        [configName: string]: any
    }
}

var configReducer: UserConfig = {
    general: {
        flowBackground: (localStorage.getItem("flowBackground") as FlowBackgroundNames) || "grid",
        accentColour: localStorage.getItem("accent") || "#1E90FF",
        theme: (localStorage.getItem("theme") as ThemeOption) || "dark",
        colourMethod: (localStorage.getItem("colourMethod") as ColourMethod) || "triad"
    },
    storage: {

    }
};

export default configReducer;
