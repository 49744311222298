import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import TriggerObject from '../types/Trigger';
import FlowObject from '../types/Flow';
import map from "lodash/map";
import { FlowTypeObject } from '../types/FlowTypes';
import FileObject from '../types/File';
import ApiKeyObject from '../types/ApiKey';
import { IMethod } from '../types/MethodReflection';
import { StorageTableDetail, StorageAddOrRemoveField, StorageAlterField, SelectUnitRequest, StorageUnitType, UpdateUnitRequest, InsertUnitRequest, DeleteUnitRequest, StorageContainerDetails } from '../types/Storage';
import { OrganizationInvitationRequest } from '../types/Organization';
import UserObject from '../types/User';
import { LoginResponse, RegistrationObject, ResetPasswordValidity, SetNewPasswordWithTokenStatus } from '../types/Auth';

type DeleteCrud = { deleted: number }

export type Dynamic<T> = T | { [name: string]: any }; //Prevents Required Parameters in types

export interface ApiCrud<T, U = (id: number | string) => AxiosPromise<T>> {
  getById?: U;
  getAll?: () => AxiosPromise<T[]>;
  post?: (add?: T) => AxiosPromise<T>;
  put?: (update?: T) => AxiosPromise<T>;
  delete?: (deleteObj?: T) => AxiosPromise<DeleteCrud>
}

export type CompositeKey<T, U, V> = (idOne: T, idTwo: U) => V;
export type TripleCompositeKey<T, U, V, W> = (idOne: T, idTwo: U, idThree: V) => W;

export interface ApiCrudCompositeKey<T, U, V> extends ApiCrud<V, CompositeKey<T, U, V>> { }
export interface ApiCrudTripleCompositeKey<T, U, V, W> extends ApiCrud<W, TripleCompositeKey<T, U, V, W>> { }

class FlowApi {
  private root = axios.create({ baseURL: "/api/" });

  instance = () => this.root;

  get<T = any>(url, config?: AxiosRequestConfig) {
    return this.root.get<T>(url, config || null);
  }

  post<T = any>(url, data?, config?: AxiosRequestConfig) {
    return this.root.post<T>(url, data || null, config || null);
  }

  put<T = any>(url, data?, config?: AxiosRequestConfig) {
    return this.root.put<T>(url, data || null, config || null);
  }

  patch<T = any>(url, data?, config?: AxiosRequestConfig) {
    return this.root.patch<T>(url, data || null, config || null);
  }

  delete<T = any>(url, config?: AxiosRequestConfig) {
    return this.root.delete<T>(url, config || null);
  }

  configure(newConfig: AxiosRequestConfig) {
    this.root = axios.create({ baseURL: '/api/', ...newConfig })
  }
}

const validStatus: (validStatuses: number[]) => { validateStatus: (statusCode: number) => boolean; } =
  (validStatuses) => ({
    validateStatus: (statusCode) => validStatuses.findIndex(x => x === statusCode) >= 0
  });
const oauthValidStatusCodes = [200, 400, 401];

const cruds = [
  { path: "getById", type: "get" },
  { path: "getAll", type: "get" },
  { path: "post", type: "post" },
  { path: "put", type: "put" },
  { path: "delete", type: "delete" }
];

export interface ApiStructure {
  trigger?: ApiCrudCompositeKey<string, string, Dynamic<TriggerObject>>;
  flow?: ApiCrudCompositeKey<number, number, Dynamic<FlowObject>> & { request?: (...params) => AxiosPromise<any> };
  flowType?: {
    getAll: () => AxiosPromise<FlowTypeObject[]>;
  },
  file?: {    //api?: AxiosInstance;
    upload?: (files: File[] | FileList, intoFolder?: string) => AxiosPromise<FileObject[]>;
    download?: (file_id: string) => AxiosPromise<File>;
    downloadFolder?: (folder_name: string) => AxiosPromise<File>;
    methodReflection?: (file_id: string) => AxiosPromise<IMethod[]>;
    imageUpload?: (files: File[] | FileList) => AxiosPromise<{ image: string }>;
    pdfUpload?: (files: File[] | FileList, progress?: (progress: ProgressEvent) => void) => AxiosPromise<{ count: number, size: number, fileName: string }>;
    pdfDownload?: (key: string, index?: number) => AxiosPromise<File>;
  },
  storage?: {
    //Containers
    createContainer: (data: StorageTableDetail) => AxiosPromise;
    listContainers: () => AxiosPromise<StorageContainerDetails[]>;
    changeContainerOwner: (data: { user_id: string; container: string; new_user_id: string; }) => AxiosPromise;
    changeContainerName: (data: { user_id: string; container: string; new_container: string; }) => AxiosPromise;
    deleteContainer: (data: { user_id: string; container: string; }) => AxiosPromise;

    //Fields
    addField: (data: StorageAddOrRemoveField) => AxiosPromise;
    removeField: (data: StorageAddOrRemoveField) => AxiosPromise;
    changeField: (data: StorageAlterField) => AxiosPromise;

    //Units
    select: (data: SelectUnitRequest) => AxiosPromise<StorageUnitType[]>;
    update: (data: UpdateUnitRequest) => AxiosPromise;
    delete: (data: DeleteUnitRequest) => AxiosPromise;
    insert: (data: InsertUnitRequest) => AxiosPromise<number>;
  },
  api_key?: {
    list: () => AxiosPromise<string[]>;
    generate: (name: string) => AxiosPromise<ApiKeyObject>;
    remove: (name: string) => AxiosPromise<boolean>;
    view: (name: string) => AxiosPromise<string>;
  }
  shortUrl?: {
    create: (url: string) => AxiosPromise<string>;
  },
  organization?: {
    inviteUser: (data: OrganizationInvitationRequest) => AxiosPromise;
    acceptInvite: (invite_id: number) => AxiosPromise;
    declineInvite: (invite_id: number) => AxiosPromise;
    leaveOrganization: (organization_id: string) => AxiosPromise;
    removeUser: (organization_id: string, user_id: string) => AxiosPromise;
    usersInOrganizations: (organization_id: string) => AxiosPromise<UserObject[]>;
  },
  auth?: {
    login: (username: string, password: string, recaptcha_token: string) => AxiosPromise<LoginResponse>;
    register: (registration: RegistrationObject) => AxiosPromise<LoginResponse>;

    //OAuth
    github: (code: string) => AxiosPromise<LoginResponse>;
    twitterToken: () => AxiosPromise<{ url: string }>;
    twitter: (authorization_id: string, oauth_token: string, oauth_verifier: string) => AxiosPromise<LoginResponse>;
    facebook: (response: any) => AxiosPromise<LoginResponse>;
    google: (tokenId: string) => AxiosPromise<LoginResponse>;
    mobiserv: (authToken: string) => AxiosPromise<LoginResponse>;

    resetPassword: (email: string, recaptcha_token: string) => AxiosPromise;
    //validateResetToken: (token: string) => AxiosPromise<{ status: ResetPasswordValidity }>;
    setPasswordWithToken: (token: string, new_password: string, recaptcha_token: string) => AxiosPromise<{ status: SetNewPasswordWithTokenStatus }>;
    setLoggedInPassword: (new_password: string) => AxiosPromise<"invalid-password" | void>;
  }
}

const entities = [
  {
    path: "trigger",
    additional: [

    ]
  },
  {
    path: "flow",
    additional: [
      { path: "request", type: "get", func: null }
    ]
  }
]

const flowApi: FlowApi & ApiStructure = new FlowApi();

entities.forEach(entity => {
  flowApi[entity.path] = {};
  cruds.forEach(crud => {
    flowApi[entity.path][crud.path] = (data) => {
      if (crud.type === "get") {
        return flowApi[crud.type](
          `${entity.path}/${crud.path}${data ? `?${map(data, (x, n) => (x ? n + '=' + encodeURIComponent(x.toString()) : "")).join('&')}` : ""}`
        );
      }
      else {
        return flowApi[crud.type](entity.path + "/" + crud.path, data);
      }
    }
  });
  entity.additional.forEach(additionalRoute => {
    flowApi[entity.path][additionalRoute.path] = (data) => {
      if (additionalRoute.type === "get") {
        return flowApi[additionalRoute.type](
          `${entity.path}/${additionalRoute.path}${data ? `?${map(data, (x, n) => (x ? n + '=' + encodeURIComponent(x.toString()) : "")).join('&')}` : ""}`
        );
      }
      else {
        return flowApi[additionalRoute.type](entity.path + "/" + additionalRoute.path, data);
      }
    }
  });
});

flowApi.file = {
  download: (file_id) => flowApi.get('file/download?file_id=' + encodeURIComponent(file_id)),
  pdfDownload: (key, index = 0) => flowApi.get(`flowbook/pdfDown?key=${encodeURIComponent(key)}&pageIndex=${encodeURIComponent(index)}`, { responseType: 'blob' }),
  downloadFolder: (folder_name) => flowApi.get('file/downloadFolder?folder_name=' + encodeURIComponent(folder_name)),
  upload: (files, folder) => {
    var form_files = new FormData();
    for (var f = 0; f < files.length; f++)
      form_files.append("file" + f, files[f]);

    form_files.append("intoFolder", encodeURIComponent(folder));
    return flowApi.post('file/upload',
      form_files,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
  },
  methodReflection: (file_id) => flowApi.get('file/methodReflection?file_id=' + encodeURIComponent(file_id)),
  pdfUpload: (files, progress = (progress: ProgressEvent) => { }) => {
    var form_files = new FormData();
    for (var f = 0; f < files.length; f++)
      form_files.append("file" + f, files[f]);

    return flowApi.post('flowbook/pdfUp',
      form_files,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: ProgressEvent => progress(ProgressEvent)
      }
    );
  },
  imageUpload: (files) => {
    var form_files = new FormData();
    for (var f = 0; f < files.length; f++)
      form_files.append("file" + f, files[f]);

    return flowApi.post('image/upload',
      form_files,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
  },
}

flowApi.flowType = {
  getAll: () => flowApi.get('flowType/getAll')
};

flowApi.storage = {
  createContainer: (data) => flowApi.post('storage/createContainer', data),
  listContainers: () => flowApi.get('storage/listContainers'),
  changeContainerOwner: (data) => flowApi.post('storage/changeContainerOwner', data),
  changeContainerName: (data) => flowApi.post('storage/changeContainerName', data),
  deleteContainer: (data) => flowApi.post('storage/deleteContainer', data),

  addField: (data) => flowApi.post('storage/addField', data),
  removeField: (data) => flowApi.post('storage/removeField', data),
  changeField: (data) => flowApi.post('storage/changeField', data),

  select: (data) => flowApi.post('storage/select', data),
  insert: (data) => flowApi.post('storage/insert', data),
  update: (data) => flowApi.post('storage/update', data),
  delete: (data) => flowApi.post('storage/delete', data)
}

flowApi.api_key = {
  list: () => flowApi.get('apiKey/list'),
  generate: (name: string) => flowApi.get(`apiKey/generate?name=${encodeURIComponent(name)}`),
  remove: (name: string) => flowApi.get(`apiKey/remove?name=${encodeURIComponent(name)}`),
  view: (name: string) => flowApi.get(`apiKey/view?name=${encodeURIComponent(name)}`),
}

flowApi.shortUrl = {
  create: (url: string) => flowApi.post('shortUrl/create', { url }, validStatus([200, 409])),
}

flowApi.organization = {
  inviteUser: (inviteData: OrganizationInvitationRequest) => flowApi.post('organization/inviteUser', inviteData),
  acceptInvite: (invite: number) => flowApi.get(`organization/acceptInvite?invite=${encodeURIComponent(invite)}`),
  declineInvite: (invite: number) => flowApi.get(`organization/declineInvite?invite=${encodeURIComponent(invite)}`),
  leaveOrganization: (organization_id: string) => flowApi.get(`organization/leave?organization_id=${encodeURIComponent(organization_id)}`),
  removeUser: (organization_id: string, user_id: string) => flowApi.get(`organization/removeUser?organization_id=${encodeURIComponent(organization_id)}&user_id=${encodeURIComponent(user_id)}`),
  usersInOrganizations: (organization_id: string) => flowApi.get(`organization/usersInOrganizations?organization_id=${encodeURIComponent(organization_id)}`)
}

flowApi.auth = {
  login: (username: string, password: string, recaptcha_token: string) => flowApi.post('auth/login', { username, password, recaptcha_token }, validStatus(oauthValidStatusCodes.concat([404]))),

  github: (code: string) => flowApi.get(`oauth/github?code=${code}`, validStatus(oauthValidStatusCodes)),  
  google: (tokenId: string) => flowApi.get(`oauth/google?token_id=${tokenId}`, validStatus(oauthValidStatusCodes)),
  facebook: (response: any) => flowApi.post(`oauth/facebook`, response, validStatus(oauthValidStatusCodes)),

  //TODO:
  twitterToken: () => flowApi.get('oauth/twitterToken'),
  twitter: (authorization_id: string, oauth_token: string, oauth_verifier: string) => flowApi.get(`oauth/twitter?authorization_id=${authorization_id}&oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`, validStatus(oauthValidStatusCodes)),
  mobiserv: (token: string) => flowApi.get(`oauth/mobiserv?token=${token}`, validStatus(oauthValidStatusCodes)),

  register: (registration: RegistrationObject) => flowApi.post('auth/register', registration),
  resetPassword: (email: string, recaptcha_token: string) => flowApi.post('auth/resetPassword', { email, recaptcha_token }),

  //validateResetToken: (token: string) => flowApi.get(`auth/validateResetToken?token=${encodeURIComponent(token)}`, validStatus([200, 400, 404])),
  setLoggedInPassword: (new_password: string) => flowApi.post('auth/setLoggedInPassword', { new_password }),
  setPasswordWithToken: (token: string, new_password: string, recaptcha_token: string) => flowApi.post('auth/setPasswordWithToken', { token, new_password, recaptcha_token }, validStatus([200, 400, 404]))
};

if (process.env.NODE_ENV === "development")
  (window as any).flowApi = flowApi; //NOTE: Allows testing api calls in the console e.g. await flowApi.trigger.getAll()

export default flowApi;