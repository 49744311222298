import ExtensionObject from "../types/Extension";

export type ExtensionGlobal = {
    list: ExtensionObject[],
}

var triggerReducer : ExtensionGlobal = {
    list: []
}

export default triggerReducer;