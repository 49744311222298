import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import injectSheet, { WithStylesProps } from 'react-jss';
import { Theme, DynamicColourType } from '../Theming';
import { ifCss } from '../helpers/CssHelpers';

interface IconButtonProps extends WithStylesProps<typeof styles> {
    disabled?: boolean;
    size?: { height: number; width: number; };
    iconSize?: SizeProp;
    icon: IconProp;
    color?: DynamicColourType | keyof Theme["background"];
    customColor?: boolean;
    use?: "accent" | "secondary" | "tertiary";
    iconColor?: string;
    variant?: "round" | "rounded-corners" | "square";
    disableHoverStyle?: boolean;
    onPress?(event: Event): void;
    component?: string | React.Component | any;
    hint?: string;
    className?: string;
    iconPosition?: "left" | "right";
    childContainerProps?: { [propName: string]: any };
    iconProps?: { [propName: string]: any }
    theme?: Theme;
    linkRef?: any;
    [propName: string]: any
}

interface IconButtonState {

}

const styles = (theme: Theme) => ({
    button: {
        "&:hover": {
            color: (props: IconButtonProps) => !props.disableHoverStyle ?
                (
                    props.customColor ?
                        theme.text[props.color || 'button'].hover
                        :
                        theme[props.use || "accent"][props.color || 'button'].textHover
                )
                :
                (
                    props.customColor ? 
                        theme.text[props.color || 'button'].$ 
                        : 
                        theme[props.use || "accent"][props.color || 'button'].text
                ),
            background: (props: IconButtonProps) => !props.disableHoverStyle ?
                (
                    props.customColor ? 
                        theme.background[props.color || 'button'].hover 
                        : 
                        theme[props.use || "accent"][props.color || 'button'].hover
                )
                :
                (
                    props.customColor ? 
                        theme.background[props.color || 'button'].$ 
                        : 
                        theme[props.use || "accent"][props.color || 'button'].$
                ),
            boxShadow: (props: IconButtonProps) => theme.shadow.button.hover,
            transition: theme.transition.hover
        },
        "&:active:focus": {
            color: (props: IconButtonProps) => props.customColor ? theme.text[props.color || 'button'].active : theme[props.use || "accent"][props.color || 'button'].textActive,
            background: (props: IconButtonProps) => props.customColor ? theme.background[props.color || 'button'].active : theme[props.use || "accent"][props.color || 'button'].active,
            boxShadow: (props: IconButtonProps) => theme.shadow.button.active,
            transition: theme.transition.hover
        },
        textDecoration: 'none',
        color: (props: IconButtonProps) => props.customColor ? theme.text[props.color || 'button'].$ : theme[props.use || "accent"][props.color || 'button'].text,
        background: (props: IconButtonProps) => props.customColor ? theme.background[props.color || 'button'].$ : theme[props.use || "accent"][props.color || 'button'].$,
        borderRadius: (props: IconButtonProps) => {
            switch(props.variant) {
                case "round":
                    return props?.size?.height ?? 32;
                default:
                case "rounded-corners":
                    return (props?.size?.height ? (props.size.height / 2) : 16);
                case "square":
                    return 6;
            }
        },
        padding: (props: IconButtonProps) => props.variant === "round" ? '8px 8px' : '8px 8px',
        boxShadow: (props: IconButtonProps) => theme.shadow.button.$,
        //transition: theme.transition.quick,
        minHeight: (props: IconButtonProps) => props.size ? props.size.height : 32,
        minWidth: (props: IconButtonProps) => props.size ? props.size.width : 32,
        height: (props: IconButtonProps) => props.size ? props.size.height : 'auto',
        width: (props: IconButtonProps) => props.size ? props.size.width : 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        "&.with-children": {
            padding: '8px 12px',
            flexDirection: 'row',
            display: 'flex'
        },

        "&.disabled": {
            cursor: 'not-allowed',
            color: theme.text.disabled.$,
            background: theme.background.disabled.$,

            "&:hover": {
                color: theme.text.disabled.hover,
                background: theme.background.disabled.hover,
            },
            "&:active:focus": {
                color: theme.text.disabled.active,
                background: theme.background.disabled.active,
            }
        }
    },
    icon: {
        "&.with-sibling": {
            marginRight: (props: IconButtonProps) => props.iconPosition === "right" ? 0 : 12,
            marginLeft: (props: IconButtonProps) => props.iconPosition !== "right" ? 0 : 12,
        }
    }
})

class IconButton extends React.Component<IconButtonProps, IconButtonState> {
    static defaultProps: IconButtonProps = {
        icon: faTimes,
        component: 'a',
        iconSize: "1x",
        classes: {} as any
    }
    render() {
        const { classes, component, onPress, linkRef, icon, color, children, variant, size, iconSize, theme, hint, disabled, iconPosition, childContainerProps, iconProps, iconColor, disableHoverStyle, ...rest } = this.props;
        const Link: any = this.props.component || 'a';
        return (
            <Link {...rest} {...(hint ? { "data-rh": hint } : {})} className={`${ifCss(rest.className, rest.className + ' ')}${classes.button}${children ? ' with-children' : ''}${disabled ? ' disabled' : ''}`} onClick={onPress} ref={linkRef}>
                {
                    children && iconPosition === "right" ?
                        <div {...childContainerProps}>{children}</div>
                        :
                        null
                }
                <FontAwesomeIcon {...iconProps} className={`${classes.icon}${children ? " with-sibling" : ""}`} icon={icon} size={iconSize} color={iconColor} />
                {
                    children && iconPosition !== "right" ?
                        <div {...childContainerProps}>{children}</div>
                        :
                        null
                }
            </Link>
        );
    }
}

export default injectSheet(styles)(IconButton);