import Localization from './Localization.json';

type AvailablePhrase = keyof typeof Localization.en;

var locale = localStorage.getItem("auth.locale") || "en";

export const SetLocale = (newLocale) => {
    locale = newLocale;
    localStorage.setItem("auth.locale", newLocale);
};

export default function Localize(key: AvailablePhrase) {
    if(Localization[locale] && Localization[locale][key]) {
        return Localization[locale][key];
    } else {
        return Localization.en[key] || key;
    }
}
