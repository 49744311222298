import OrganizationObject from '../types/Organization'; 

export interface OrganizationReducer {
    list: OrganizationObject[];
}

var organizationReducer: OrganizationReducer  = {
    list: []
}

export default organizationReducer;