import flowListReducer from './flowListReducer';
import flipBookListReducer from './flipBookListReducer';
import preloadedPDFReducer from './preloadedPDFReducer';
import configReducer from './configReducer';
import apiKeyReducer from './apiKeyReducer';
import flowTypeReducer from './flowTypeReducer';
import triggerReducer from './triggerReducer';
import extensionsReducer from './extensionsReducer';
import sessionReducer from './sessionReducer';
import authReducer, { login, logout } from './authReducer';
import storageReducer from './storageReducer';
import pageLoadReducer from './pageLoadReducer';
import invitationReducer from './invitationReducer';
import organizationReducer from './organizationReducer'
import bannerReducer, { showBanner, clearBanners } from './bannerReducer';

const reducers = {
  apiKey: apiKeyReducer,
  auth: authReducer,
  sessionVars: sessionReducer,
  flowList: flowListReducer,
  flipBookList: flipBookListReducer,
  preloadedPDF: preloadedPDFReducer,
  config: configReducer,
  flowTypes: flowTypeReducer,
  triggers: triggerReducer,
  extensions: extensionsReducer,
  storage: storageReducer,
  banners: bannerReducer,
  pageLoad: pageLoadReducer,
  invitations: invitationReducer,
  organizations: organizationReducer,
  redirect: undefined
};

export const reducerFunctions = {
  login,
  logout,
  showBanner, toast: showBanner,
  clearBanners,
  redirect: (global, dispatch, to) => {
    setTimeout(() => {
      dispatch({ redirect: undefined });
    }, 100);
    return ({ redirect: to }) 
  }
};

type GlobalReducer = typeof reducers;
type GlobalFunctionReducers = typeof reducerFunctions;

export interface GlobalReducers extends GlobalFunctionReducers {}

export interface GlobalState extends GlobalReducer {
  //firestore: firestore.Firestore
}

export default reducers;