import React from 'react';
import injectSheet, { WithStylesProps } from 'react-jss';
import { Portal } from 'react-portal';
import {
    faExclamationCircle,
    faExclamationTriangle,
    faBellExclamation,
    faBell,
    faExclamationSquare,
    faExclamation,
    faUser,
    faUserCog,
    faProjectDiagram,
    faLayerGroup,
    faTrafficLightGo,
    faPuzzlePiece,
    faCog,
    faQuestion,
    faQuestionCircle,
    faQuestionSquare,
    faSignOutAlt,
    faLock,
    faFolders,
    faFile,
    faBoxOpen,
    faMobile,
    faSave,
    faBug,
    faDebug,
    faCheck,
    faCheckDouble,
    faTimes,
    faTrash,
    faTrashAlt,
    faICursor,
    faUserCrown,
    faClipboardCheck,
    faReply,
    faEnvelope,
    faEnvelopeOpen,
    faLayerPlus
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, DynamicColour } from '../Theming';
import { BannerObject } from '../reducers/bannerReducer';
import { TinyColor } from '@ctrl/tinycolor';

const red = '#f01c00';
const yellow = '#f5c800';
const green = '#00c742';

const colors = {
    "light": {
        'red': {
            'sidebar': new DynamicColour(new TinyColor(red), "sidebar", "light"),
            'inverted': new DynamicColour(new TinyColor(red), "inverted", "light"),
        },
        'yellow': {
            'sidebar': new DynamicColour(new TinyColor(yellow), "sidebar", "light"),
            'inverted': new DynamicColour(new TinyColor(yellow), "inverted", "light"),
        },
        'green': {
            'sidebar': new DynamicColour(new TinyColor(green), "sidebar", "light"),
            'inverted': new DynamicColour(new TinyColor(green), "inverted", "light"),
        }
    },
    "dark": {
        'red': {
            'sidebar': new DynamicColour(new TinyColor(red), "sidebar", "dark"),
            'inverted': new DynamicColour(new TinyColor(red), "inverted", "dark"),
        },
        'yellow': {
            'sidebar': new DynamicColour(new TinyColor(yellow), "sidebar", "dark"),
            'inverted': new DynamicColour(new TinyColor(yellow), "inverted", "dark"),
        },
        'green': {
            'sidebar': new DynamicColour(new TinyColor(green), "sidebar", "dark"),
            'inverted': new DynamicColour(new TinyColor(green), "inverted", "dark"),
        }
    }
}

const getColor = (colorName, lightDark, theme: Theme, inverted?: boolean): DynamicColour => {
    switch (colorName) {
        case 'accent':
        case 'secondary':
        case 'tertiary':
            return theme[colorName][inverted ? "inverted" : "sidebar"];
        default:
            return colors[lightDark][colorName][inverted ? "inverted" : "sidebar"];
    }
}

const styles = (theme: Theme) => ({
    container: {
        ...(theme.accent.sidebar.toJss({
            $: {
                boxShadow: theme.shadow.banner.$,
            },
            hover: {
                boxShadow: theme.shadow.banner.hover,
            },
            active: {
                boxShadow: theme.shadow.banner.active,
            },
            focus: {
                boxShadow: theme.shadow.banner.focus,
            },
        })),
        userSelect: 'none',
        position: 'fixed',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 600,
        width: '100%',
        height: 64,
        borderRadius: 8,
        zIndex: (props: BannerProps) => 9999 - props.activeIndex, //NOTE TO SELF, make less than hint
        flexDirection: 'row',
        cursor: (props: BannerProps) => props.action ? 'pointer' : undefined,
        borderTop: `2px solid ${theme.accent.border.$}`,
        transition: theme.transition.quick,

        //'red' | 'yellow' | 'green' | 'accent' | 'secondary' | 'tertiary';
        "&.banner-accent": {
            ...(getColor('accent', theme.code, theme).toJss()),
            borderTop: `2px solid ${theme.accent.border.$}`
        },
        "&.banner-secondary": {
            ...(getColor('secondary', theme.code, theme).toJss()),
            borderTop: `2px solid ${theme.secondary.border.$}`
        },
        "&.banner-tertiary": {
            ...(getColor('tertiary', theme.code, theme).toJss()),
            borderTop: `2px solid ${theme.tertiary.border.$}`
        },
        "&.banner-red": {
            ...(getColor('red', theme.code, theme).toJss()),
            borderTop: `2px solid #c22915`
        },
        "&.banner-yellow": {
            ...(getColor('yellow', theme.code, theme).toJss()),
            borderTop: `2px solid #c28015`
        },
        "&.banner-green": {
            ...(getColor('green', theme.code, theme).toJss()),
            borderTop: `2px solid #00b63e`
        }
    },
    buttons: {
        position: 'absolute',
        right: 10,
        top: 6,
        flexDirection: 'row'
    },
    symbol: {
        width: 56,
        justifyContent: 'center',
        alignItems: 'center'
    },
    body: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    title: {
        userSelect: 'none',
        fontWeight: 'bold',
        lineHeight: '1.1em',
        paddingBottom: 2
    },
    message: {
        userSelect: 'none',
        fontSize: '0.9em',
        lineHeight: '1.1em',
        whiteSpace: 'pre-wrap'
    },
    bannerButton: {
        ...(theme.accent.inverted.toJss()),
        borderRadius: "50%",
        height: 20,
        width: 20,
        zIndex: 1,
        cursor: 'pointer',
        transition: theme.transition.quick,

        "&.banner-button-accent": {
            ...(getColor('accent', theme.code, theme, true).toJss())
        },
        "&.banner-button-secondary": {
            ...(getColor('secondary', theme.code, theme, true).toJss())
        },
        "&.banner-button-tertiary": {
            ...(getColor('tertiary', theme.code, theme, true).toJss())
        },
        "&.banner-button-red": {
            ...(getColor('red', theme.code, theme, true).toJss())
        },
        "&.banner-button-yellow": {
            ...(getColor('yellow', theme.code, theme, true).toJss())
        },
        "&.banner-button-green": {
            ...(getColor('green', theme.code, theme, true).toJss())
        }
    }
})

//type BannerPosition = "top" | "bottom";

type BannerStyledProps = WithStylesProps<typeof styles> & BannerObject;
interface BannerProps extends BannerStyledProps {
    activeIndex?: number;
    onDismiss?: () => void;
}

const icons = {
    default: faExclamationCircle,
    "exclamation-circle": faExclamationCircle,
    "exclamation-triangle": faExclamationTriangle,
    "exclamation-square": faExclamationSquare,
    exclamation: faExclamation,
    bell: faBell,
    "bell-exclamation": faBellExclamation,
    user: faUser,
    "user-cog": faUserCog,
    "project-diagram": faProjectDiagram,
    "layer-group": faLayerGroup,
    "traffic-light-go": faTrafficLightGo,
    "puzzle-piece": faPuzzlePiece,
    cog: faCog,
    question: faQuestion,
    "question-circle": faQuestionCircle,
    "question-square": faQuestionSquare,
    "sign-out-alt": faSignOutAlt,
    lock: faLock,
    folders: faFolders,
    file: faFile,
    "box-open": faBoxOpen,
    mobile: faMobile,
    save: faSave,
    bug: faBug,
    debug: faDebug,
    check: faCheck,
    "check-double": faCheckDouble,
    trash: faTrash,
    "trash-alt": faTrashAlt,
    "i-cursor": faICursor,
    "user-crown": faUserCrown,
    "clipboard-check": faClipboardCheck,
    reply: faReply,
    envelope: faEnvelope,
    "envelope-open": faEnvelopeOpen,
    "layer-plus": faLayerPlus
}

export type BannerIconName = KeyOf<typeof icons>;

class Banner extends React.Component<BannerProps> {

    static defaultProps: BannerProps = {
        title: "<Missing>",
        color: "accent",
        dismissable: true,
        icon: "default",
        classes: { } as any
    }

    timeoutId?= null;

    performDismiss() {
        if (this.props.onDismiss)
            this.props.onDismiss();

        clearTimeout(this.timeoutId);
        this.timeoutId = null;
    }

    componentDidUpdate() {
        if (this.props.timeout && !this.timeoutId && this.props.activeIndex >= 0) {
            this.timeoutId = setTimeout(this.performDismiss.bind(this), this.props.timeout);
        }
    }

    render() {
        const { classes, dismissable, option, icon, title, message, color, action, activeIndex } = this.props;

        const newStyle = {
            top: (
                title === '<Missing>' ?
                    -64
                    :
                    (activeIndex * 64) + ((activeIndex + 1) * 10)
            ),
            ...(activeIndex >= 0 && title !== '<Missing>' ? ({}) : ({ boxShadow: 'none' }))
        };

        return (
            <React.Fragment>
                <Portal>
                    <div
                        className={`${classes.container} banner-${color}`}
                        onClick={(e) => {
                            if (action) {
                                var actionResponse = action(e);
                                if (actionResponse && actionResponse.dismiss) {
                                    this.performDismiss();
                                }
                            }
                        }}
                        style={newStyle}
                    >
                        {
                            dismissable || option ?
                                <div className={classes.buttons}>
                                    {option && <div
                                        className={`${classes.bannerButton} banner-button-${color}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (option.action) {
                                                var actionResponse = option.action(e);
                                                if (actionResponse && actionResponse.dismiss) {
                                                    this.performDismiss();
                                                }
                                            }
                                        }}
                                        {...(option.hint ? { "data-rh": option.hint } : {})}
                                    >
                                        <FontAwesomeIcon
                                            icon={icons[option.icon]}
                                        />
                                    </div>
                                    }
                                    {option && dismissable && <>&nbsp;&nbsp;</>}
                                    {dismissable && <div
                                        className={`${classes.bannerButton} banner-button-${color}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.performDismiss();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                        />
                                    </div>
                                    }
                                </div>
                                :
                                null
                        }
                        <div className={classes.symbol}>
                            <FontAwesomeIcon icon={icons[icon]} size="2x" />
                        </div>
                        <div className={classes.body}>
                            <div className={classes.title}>{title}</div>
                            <div className={classes.message}>{message}</div>
                        </div>
                    </div>
                </Portal>
            </React.Fragment>
        );
    }
}

export default injectSheet(styles)(Banner);