
import { OrganizationInvitationObject } from '../types/Organization'; 

export interface InvitationsReducer {
    pendingInvitations: OrganizationInvitationObject[];
}

var invitationReducer: InvitationsReducer  = {
    pendingInvitations: []
}

export default invitationReducer;