import TriggerObject from "../types/Trigger";
import FlowObject from "../types/Flow";
import FlipBookObject from "../types/FlipBook";
import FlowBookDeliveryObject from "../types/FlowBookDelivery";
import { FlowTypeObject } from "../types/FlowTypes";
import Axios from "axios";
import forEach from 'lodash/forEach';
import { isDayjs } from 'dayjs';
import FileObject from "../types/File";
import FolderObject from "../types/Folder";
import ExtensionObject from "../types/Extension";
import OrganizationObject, { OrganizationInvitationObject } from "../types/Organization";
import UserObject from "../types/User";

type QpiCompare =
    ("equals" | "==") |
    ("notEquals" | "!=") |
    "contains" |
    "doesNotContain" |
    "startsWith" |
    "endsWith" |
    ("greaterThan" | ">") |
    ("lessThan" | "<") |
    ("greaterThanOrEquals" | ">=") |
    ("lessThanOrEquals" | "<=");

type QpiCompareArray = "in" |
    "notIn";

type QpiOrderDirection = "asc" | "desc";

type QpiHeaders = { [name: string]: string };

//type KeyOrArrayKey<T> = T[KeyOf<T>] extends Array<any> ? T[KeyOf<T>] & "." & T[KeyOf<T>][0] : KeyOf<T>;

class QpiWhere<T> {
    constructor(parameter: KeyOf<T> | KeysOfType<T, Array<any>>, comparer: QpiCompare | QpiCompareArray, value: any) {
        this.parameter = parameter;
        this.comparer = comparer;
        this.value = value;
    }
    parameter: KeyOf<T> | KeysOfType<T, Array<any>>;
    comparer: QpiCompare | QpiCompareArray;
    value: any;
}

class QpiArrayWhere<T, S extends KeysOfType<T, Array<any>>, U extends Array<T[S]>> extends QpiWhere<T> {
    constructor(parameter: S, subItem: KeyOf<U[0]>, comparer: QpiCompare | QpiCompareArray, value: any) {
        super(parameter, comparer, value);
        this.subParameter = subItem;
    }

    parameter: KeysOfType<T, Array<any>>;
    subParameter?: KeyOf<U[0]>;
    comparer: QpiCompare | QpiCompareArray;
    value: any;
}

class QpiOrderBy<T> {
    constructor(parameter: KeyOf<T>, direction?: QpiOrderDirection) {
        this.parameter = parameter;
        if (direction)
            this.direction = direction;
    }
    parameter: KeyOf<T>;
    direction?: QpiOrderDirection;
}

class QpiSelect<T> {
    constructor(tableName, headers: QpiHeaders) {
        this.table = tableName;
        this.headers = headers;
        this.wheres = [];
        this.orderBys = [];
        this.includes = [];
    }
    /** visible in QpiGroupedSelect */
    protected table;
    protected headers: QpiHeaders;

    where<S extends Exclude<KeyOf<T>, KeysOfType<T, Array<any>>>, V, C extends (V extends Array<any> ? QpiCompareArray : QpiCompare)>(parameter: S, comparer: C, value: V): QpiSelect<T> {
        let valueOut;
        if (isDayjs(value)) {
            valueOut = value.toISOString();
        } else {
            valueOut = value;
        }

        let comparerOut;

        switch (comparer) {
            case "==":
                comparerOut = "equals";
                break;
            case "!=":
                comparerOut = "notEquals";
                break;
            case ">":
                comparerOut = "greaterThan";
                break;
            case ">=":
                comparerOut = "greaterThanOrEquals";
                break;
            case "<":
                comparerOut = "lessThan";
                break;
            case "<=":
                comparerOut = "lessThanOrEquals";
                break;
            default:
                comparerOut = comparer;
                break;
        }
        this.wheres.push(new QpiWhere(parameter, comparerOut, valueOut));
        return this;
    }
    
    whereArray<S extends KeysOfType<T, Array<any>>, U extends (T[S] extends Array<any> ? T[S] : undefined), V, C extends (V extends Array<any> ? QpiCompareArray : QpiCompare)>(parameter: S, subParameter: KeyOf<U[0]>, comparer: C, value: V): QpiSelect<T> {
        let valueOut;
        if (isDayjs(value)) {
            valueOut = value.toISOString();
        } else {
            valueOut = value;
        }

        this.wheres.push(new QpiArrayWhere(parameter, subParameter, comparer, valueOut));
        return this;
    }

    //protected whereArrays: QpiWhere<T>[];
    protected wheres: QpiWhere<T>[];

    orderBy(parameter: KeyOf<T>, direction?: QpiOrderDirection): QpiSelect<T> {
        this.orderBys.push(new QpiOrderBy<T>(parameter, direction));
        return this;
    }
    protected orderBys: QpiOrderBy<T>[];

    groupBy<S extends KeyOf<T>>(parameter: KeyOf<T>): QpiGroupedSelect<T, S> {
        return new QpiGroupedSelect<T, S>(this, this.table, this.headers, parameter);
    }

    include<S extends Exclude<KeyOf<T>, KeysOfType<T, Array<any>>>, U extends Exclude<KeyOf<T[S]>, KeysOfType<T[S], Array<any>>>>(parameter: S, subparameter?: U): QpiSelect<T> {
        this.includes.push(parameter + (subparameter ? `.${subparameter}` : ""));
        return this;
    }

    /* TODO:
    private includeArray<S extends KeysOfType<T, Array<any>>>(parameter: S, subparameter?: T[S] extends Array<any> ? KeyOf<T[S][0]> : KeyOf<T[S]>): QpiSelect<T> {
        throw "not implemented";
        this.arrayIncludes.push(parameter + (subparameter ? `.${subparameter}` : ""));
        return this;
    }
    */

    protected includes: string[];
    protected arrayIncludes: string[];

    protected limit?: number;
    protected skip?: number;

    query<S = T>(restrictions?: { skip?: number, limit?: number }): Promise<S[]> {
        if (restrictions && (restrictions.limit || restrictions.skip)) {
            if (restrictions.limit) {
                this.limit = restrictions.limit;
            }

            if (restrictions.skip) {
                this.skip = restrictions.skip;
            }
        }
        var self = this;
        return new Promise<S[]>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }

    first<S = T>(): Promise<S> {
        var self = this;
        return new Promise<S>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    if (data.length > 0)
                        resolve(data[0]);
                    else
                        resolve(null);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }

    single<S = T>(): Promise<S> {
        var self = this;
        return new Promise<S>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    if (data.length === 1) {
                        resolve(data[0]);
                    }
                    else if (data.length > 1) {
                        console.error(data);
                        reject(new Error("More than one result found"));
                    }
                    else reject(new Error("No result found"));                    
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                })
        })
    }
}

class QpiGroupedSelect<Base, T> extends QpiSelect<Base> { //TODO: Implement Backend
    constructor(baseSelect: QpiSelect<Base>, headers, tableName, groupBy: KeyOf<Base>) {
        super(tableName, headers);
        Object.assign(this, baseSelect)
        this.groupBys = [];
        this.groupBys.push(groupBy);
    }
    protected groupBys: KeyOf<Base>[];
    andBy(parameter: KeyOf<Base>): QpiGroupedSelect<Base, T> {
        this.groupBys.push(parameter);
        return this;
    }

    query<S = Base>(restrictions?: { skip?: number, limit?: number }): Promise<S[]> {
        if (restrictions && (restrictions.limit || restrictions.skip)) {
            if (restrictions.limit) {
                this.limit = restrictions.limit;
            }

            if (restrictions.skip) {
                this.skip = restrictions.skip;
            }
        }
        var self = this;
        return new Promise<S[]>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }

    first<S = Base>(): Promise<S> {
        var self = this;
        return new Promise<S>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    if (data.length > 0)
                        resolve(data[0]);
                    else
                        resolve(null);
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }

    single<S = Base>(): Promise<S> {
        var self = this;
        return new Promise<S>((resolve, reject) => {
            Axios.post<S[]>("/qpi/select", self, { headers: this.headers })
                .then(({ data }) => {
                    if (data.length === 1)
                        resolve(data[0]);
                    else
                        reject(new Error("More than one result found"));
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }
}

class QpiUpdate<T> {
    constructor(tableName, headers: QpiHeaders) {
        this.table = tableName;
        this.wheres = [];
        this.changes = {};
        this.headers = headers;
    }
    private table: string;
    private wheres: QpiWhere<T>[];
    private changes: { [name: string]: any }
    private headers: QpiHeaders;

    where(parameter: KeyOf<T>, comparer: QpiCompare, value: any): QpiUpdate<T> {
        if (isDayjs(value)) {
            value = value.toISOString();
        }

        switch (comparer) {
            case "==":
                comparer = "equals";
                break;
            case "!=":
                comparer = "notEquals";
                break;
            case ">":
                comparer = "greaterThan";
                break;
            case ">=":
                comparer = "greaterThanOrEquals";
                break;
            case "<":
                comparer = "lessThan";
                break;
            case "<=":
                comparer = "lessThanOrEquals";
                break;
        }
        this.wheres.push(new QpiWhere(parameter, comparer, value));
        return this;
    }

    set(property: KeyOf<T>, value: any): QpiUpdate<T> {
        this.changes[property] = value;
        return this;
    }

    bulkSet(newObject: T): QpiUpdate<T> {
        const tmp = newObject as any;
        forEach(tmp, (value, property) => {
            this.changes[property] = value;
        });
        return this;
    }

    execute(): Promise<number> {
        var self = this;
        return new Promise<number>((resolve, reject) => {
            Axios.post<number>("/qpi/update", self, { headers: this.headers })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }
}

class QpiDelete<T> {
    constructor(tableName, headers: QpiHeaders) {
        this.table = tableName;
        this.wheres = [];
        this.headers = headers;
    }
    private table: string;
    private wheres: QpiWhere<T>[];
    private headers: QpiHeaders;

    where(parameter: KeyOf<T>, comparer: QpiCompare, value: any): QpiDelete<T> {
        if (isDayjs(value)) {
            value = value.toISOString();
        }

        switch (comparer) {
            case "==":
                comparer = "equals";
                break;
            case "!=":
                comparer = "notEquals";
                break;
            case ">":
                comparer = "greaterThan";
                break;
            case ">=":
                comparer = "greaterThanOrEquals";
                break;
            case "<":
                comparer = "lessThan";
                break;
            case "<=":
                comparer = "lessThanOrEquals";
                break;
        }
        this.wheres.push(new QpiWhere(parameter, comparer, value));
        return this;
    }

    execute(): Promise<number> {
        var self = this;
        return new Promise<number>((resolve, reject) => {
            Axios.post<number>("/qpi/delete", self, { headers: this.headers })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }
}

class QpiTable<T> {
    constructor(tableName, headers = {}) {
        this.table = tableName;
        this.headers = headers;
    }
    private table: string;
    private headers: QpiHeaders;

    get select(): QpiSelect<T> {
        var selectObject = new QpiSelect<T>(this.table, this.headers);
        return selectObject;
    }

    insert(newEntity: T): Promise<T> {
        var self = this;
        return new Promise<T>((resolve, reject) => {
            Axios.post<T>("/qpi/insert", { table: self.table, data: newEntity }, { headers: this.headers })
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((e) => {
                    reject(e);
                })
        })
    }

    get update(): QpiUpdate<T> {
        var updateObject = new QpiUpdate<T>(this.table, this.headers);
        return updateObject;
    }

    get delete(): QpiDelete<T> {
        var deleteObject = new QpiDelete<T>(this.table, this.headers);
        return deleteObject;
    }

    clearHeaders() {
        this.headers = {};
    }

    setAuthorizationHeader(value: string) {
        this.headers["Authorization"] = value;
    }

    setHeader(key: string, value: string) {
        this.headers[key] = value;
    }

    removeHeader(key: string) {
        delete this.headers[key];
    }
}

class QpiInstance {
    forAll = (action: (table: QpiTable<any>) => void) => {
        forEach(this, (table, tableName) => {
            if (tableName !== "forAll") {
                action(table as any);
            }
        })
    }
}

class QpiCAPi extends QpiInstance {
    flows: QpiTable<FlowObject> = new QpiTable<FlowObject>("flows");
    flowbook: QpiTable<FlipBookObject> = new QpiTable<FlipBookObject>("flowbooks");
    flowbook_delivery: QpiTable<FlowBookDeliveryObject> = new QpiTable<FlowBookDeliveryObject>("flowbook_delivery");
    flow_type: QpiTable<FlowTypeObject> = new QpiTable<FlowTypeObject>("flow_type");
    triggers: QpiTable<TriggerObject> = new QpiTable<TriggerObject>("triggers");
    extensions: QpiTable<ExtensionObject> = new QpiTable<ExtensionObject>("extensions");
    files: QpiTable<FileObject> = new QpiTable<FileObject>("files");
    folders: QpiTable<FolderObject> = new QpiTable<FolderObject>("folders");
    organizations: QpiTable<OrganizationObject> = new QpiTable<OrganizationObject>("organizations");
    organization_user_invitations: QpiTable<OrganizationInvitationObject> = new QpiTable<OrganizationInvitationObject>("organization_user_invitations");
    users: QpiTable<UserObject> = new QpiTable<UserObject>("users");
}

const Qpi = new QpiCAPi();

if (process.env.NODE_ENV === "development")
    (window as any).Qpi = Qpi;

export default Qpi;