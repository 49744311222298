import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../capi-start-your-engines.json';
import injectSheet from 'react-jss';
import { Theme } from '../../Theming.jsx';
import { Css } from '../../types/CssTypes.js';

const styles: any = (theme: Theme): Css => ({
  container: {
    '& svg g g': {
      '& g.pacman path': {
        stroke: theme.logo.animations.front
      },
      '& g.track path': {
        stroke: theme.logo.animations.front,
        fill: theme.logo.animations.front
      }
    }
  }
});

class StartYourEngines extends React.Component<{
  color?: 'black';
  size?: 'tiny' | 'small' | 'large' | number;
  style?: any;
  classes?: any;
  className?: any;
}> {
  render() {
    const tiny = 32;
    const small = 48;
    const large = 100;

    var size;

    if (typeof this.props.size === 'number') {
      size = this.props.size;
    } else if (this.props.size === 'tiny') {
      size = tiny;
    } else if (this.props.size === 'large') {
      size = large;
    } else {
      size = small;
    }

    return (
      <div
        className={this.props.className || this.props.classes.container}
        style={{
          ...{ height: size, width: size },
          ...this.props.style
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          isClickToPauseDisabled
        />
      </div>
    );
  }
}

export default injectSheet(styles)(StartYourEngines);
