export interface PreloadedPDFState {  
  list: {
      [fileName: string] : {
        isDownloading?: boolean,
        data?: string,    
        pdf?: any,
        [pageNumber: number]: any
      }
    };
}

var preloadedPDFReducer: PreloadedPDFState = {
  list: {}
};

export default preloadedPDFReducer;
