import * as HumanizerUntyped from 'humanize-plus';

interface humanizerjs {
    camelcaseToHuman(name: string): string;

    titleCase(value: string): string;

    capitalizeAll(value: string): string;

    capitalize(value: string): string;

    truncate(value: string, maxLength?: number, postfix?: string): string;

    truncateWords(value: string, maxLength?: number, postfix?: string): string;

    fileSize(value: number): string;

    oxford(value: string[], maximumDisplayed?: number): string;

    intComma(value: number): string;

    formatNumber(value: number, decimalPlaces: number): string;

    compactInteger(value: number, precision: number): string;

    boundedNumber(value: number, maxValue: number): string;

    ordinal(value: number): string;

    times(value: number): string;

    pace(value: number, timeFrame: number, postfix?: string): string;

    pluralize(quantity: number, ofObject: string, pluralForm?: string): string;

    frequency(values: any[], actionPostfix: string): string;

    toFixed(value: number, decimalPlaces: number): string;

    normalizePrecision(value: number): string;

    [name: string]: any;
}
var Humanizer: humanizerjs = HumanizerUntyped;
function camelcaseToHuman(name: string): string {
    return Humanizer.capitalizeAll(name.replace("_", " ").replace("-", " ")).replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }).trim();
}
Humanizer.camelcaseToHuman = camelcaseToHuman;
export default Humanizer;
