import React from 'react';
import StartYourEngines from '../components/animations/StartYourEngines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrafficLightGo, faTrafficLightStop, faKeyboard, faSearchPlus, faWandMagic, faTrafficLightSlow, faExclamationCircle, faArrowAltSquareDown, faArrowAltSquareUp } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltSquareLeft, faArrowAltSquareRight } from '@fortawesome/pro-solid-svg-icons';
import Localize from '../Localize';

const GuideContent = ({ children }) => {
    return (
        <div style={{ display: 'block', marginTop: 32, marginBottom: 20, width: '100%', flex: 1, textAlign: 'center' }}>
            {children}
        </div>
    );
}

const Intro = () => [
    {
        selector: null,
        badge: Localize('Welcome!'),
        content: (
            <GuideContent>
                <div style={{ width: '100%' }}><StartYourEngines size="large" /></div>
                <br />
                <h3 dangerouslySetInnerHTML={{ __html: Localize("Welcome to Flow Builder!") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Ready to get started?") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("You can <strong><em>Navigate</em></strong> with the <strong><em>arrow keys</em></strong>") }} style={{ fontSize: '0.8em' }} />
                <div style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <FontAwesomeIcon icon={faArrowAltSquareLeft} size="lg" />
                    <div style={{ flexDirection: 'column', opacity: 0.5 }}>
                        <FontAwesomeIcon icon={faArrowAltSquareUp} size="lg" />
                        <FontAwesomeIcon icon={faArrowAltSquareDown} size="lg" />
                    </div>
                    <FontAwesomeIcon icon={faArrowAltSquareRight} size="lg" />
                </div>
            </GuideContent>
        )
    },
    {
        selector: '#node-start',
        badge: Localize('Start Node'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faTrafficLightGo} color="rgba(0,204,0,1)" size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>This</em></strong> is the <strong><u>Start Node</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("When you create a new flow, this node will change depending on which type you're creating.") }}/>
                <p dangerouslySetInnerHTML={{ __html: Localize("It also gives you access to some nice starting <strong><em>variables</em></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>Variables</em></strong> are collected information that's been made available to you.") }} />
            </GuideContent>
        )
    },
    {
        selector: '#start_end',
        badge: Localize('Edge'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faTrafficLightSlow} color="rgba(204,204,0,1)" size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>This</em></strong> is an <strong><u>Edge</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Edges connect two nodes together. It's the only way for flow builder to know where to go next.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("There are many different types of edges, not just this simple one.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Some of the more advanced edges indicate whether something was successful or a failure and alters direction accordingly.") }} />
            </GuideContent>
        )
    },
    {
        selector: '#node-end',
        badge: Localize('Output Node'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faTrafficLightStop} color="rgba(204,0,0,1)" size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>This</em></strong> is the <strong><u>Output Node</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Whenever you need to <em>respond to any request</em>, you can enter it in the detailed menu on the right.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("It's <strong><em>Very Important</em></strong> that you make sure to <strong>Always End on an Output Node</strong>.<br />If you don't you could have a few issues for your users...") }} />
            </GuideContent>
        )
    },
    {
        selector: '#node-detail',
        badge: Localize('Details Panel'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faSearchPlus} size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>This</em></strong> is the <strong><u>Details Panel</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("As explained earlier, these are the <em>variables</em> available throughout the flow") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("If you click on any node, it will bring up all the details and configurations about that node.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("If you ever need to change a nodes <em>Name</em> or anything, this is where you can go.") }} />
            </GuideContent>
        )
    },
    {
        selector: '#flow-overlay',
        badge: Localize('Node Selection'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faWandMagic} size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>These</em></strong> are your available <strong><u>Nodes</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("You can <strong><em>Drag in any node</em></strong> from this list and start working with them anywhere on your flow.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("You can even create your own and share them with the world!") }} />
            </GuideContent>
        )
    },
    {
        selector: '.rtf',
        badge: Localize('Action Buttons'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faExclamationCircle} color={'#007aff'} size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>This</em></strong> is the <strong><u>Action Button</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Any general actions that you might need can be found right here!") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("A few essetial ones: <em>Save, Debug, Test</em> and some others that may vary based on your flow type.") }} />
            </GuideContent>
        )
    },
    {
        selector: '#flow-legends',
        badge: Localize('Shortcuts'),
        content: (
            <GuideContent>
                <div>
                    <FontAwesomeIcon icon={faKeyboard} size="5x" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>These</em></strong> are some of our <strong><u>Shortcuts</u></strong>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>Add Node</em></strong>, lets you add the last node you clicked on or created") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>Connect Nodes</em></strong>, lets you connect two nodes together with an edge. From the first node to the second node will create an edge in that direction.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>Undo</em></strong>, lets you undo any major changes on the flow. <br /><em>Note: Make sure to focus on the flow graph itself when you press it!</em>") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("<strong><em>Redo</em></strong>, lets you redo any changes you undid on the flow. <br /><em>Note: Same rules apply as Undo</em>") }} />
            </GuideContent>
        )
    },
    {
        selector: null,
        badge: Localize('Done!'),
        content: (
            <GuideContent>
                <h2>{Localize("Done!")}</h2>
                <p dangerouslySetInnerHTML={{ __html: Localize("That's right!<br />You've learned all you need to make amazing flows a reality.") }} />
                <p dangerouslySetInnerHTML={{ __html: Localize("Now <strong><em>click</em></strong> the <strong><em>finish</em></strong> button and start making flows!") }} />
            </GuideContent>
        )
    },
]

const TourGuides = {
    Intro
};

export default TourGuides;
