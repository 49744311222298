export type PageLoadGlobal = {
    extensionsLoaded: boolean;
    triggersLoaded: boolean;
    flowsLoaded: boolean;
    flowDefinitionsLoaded: boolean;
    storageContainersLoaded: boolean;
}

var pageLoadReducer : PageLoadGlobal = {
    extensionsLoaded: false,
    triggersLoaded: false,
    flowsLoaded: false,
    flowDefinitionsLoaded: false,
    storageContainersLoaded: false
}

export default pageLoadReducer;