import React from 'react';
import ReactDOM from 'react-dom';
import { setGlobal, addReducer } from 'reactn';
import forEach from 'lodash/forEach';

import './Theming';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import initWebRequestListener from './WebRequest';
import { setupConsoles } from './helpers/ConsoleHelper';
import combineReducer, { reducerFunctions } from './reducers';

/*if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}*/

setupConsoles();
setGlobal(combineReducer);
forEach(reducerFunctions, (value, key) => addReducer(key, value));

initWebRequestListener();

// const forceSWUpdate = (registration: ServiceWorkerRegistration) => {
//     const registrationWaiting = registration.waiting;
//     if (registrationWaiting) {
//         registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
//         registrationWaiting.addEventListener('statechange', (e) => {
//             if ((e.target as any).state === 'activated') {
//                 window.location.reload();
//             }
//         });
//     }
// }

ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV !== "development") {
    serviceWorker.unregister();
}

//Currently Having issues with PWA Service Worker Not Updating After Registering
/*,
()) => {
    if (process.env.NODE_ENV !== "development") {
        console.debug("Registering Service Worker");
        try {
            serviceWorker.register({ onUpdate: forceSWUpdate });
        } catch (e) {
            if (e.name === "SecurityError") {
                console.error("Firefox: only `https` requests are allowed for this operation", e);
            } else {
                console.error("Browser Cookies not Enabled", e);
            }
        }
    }
);
}*/