import { EventEmitter } from 'events';
import tinycolor, { TinyColor, mostReadable } from '@ctrl/tinycolor';

interface HoverableStyle {
    $: string;
    hover?: string;
    active?: string;
    focus?: string;
}

export type DynamicColourType = "button" | "inverted" | "background" | "border" | "sidebar" | "node" | "listItem"// | "muted" |;
type ThemeColours = "light" | "dark";

const textColours = ["#111", "#222", "#333", "#444", "#555", "#666", "#777", "#888", "#999", "#aaa", "#bbb", "#ccc", "#ddd", "#eee"];

const ifExtras = function (extras, sub: "$" | "hover" | "active" | "focus") {
    if(extras && extras[sub]) {
        return extras[sub]
    } else {
        return {};
    }
}

export class DynamicColour implements HoverableStyle {
    constructor(colour: TinyColor, type: DynamicColourType = "button", lightDark: ThemeColours = "light") {
        this.__type = type;

        this._tinyPrimaryColour = colour.toRgbString();

        switch (type) {
            case "button":
                this.mainColour = lightDark === "light" ? "#ffffff" : "#444444";
                this.hoverColour = colour.toRgbString();//default = 10
                this.activeColour = colour.darken().toRgbString();//default = 10
                this.focusColour = this.hoverColour;

                this.textColour = colour.toRgbString();
                this.textHoverColour = mostReadable(tinycolor(this.hoverColour), textColours).toRgbString();
                this.textActiveColour = mostReadable(tinycolor(this.activeColour), textColours).toRgbString();
                this.textFocusColour = mostReadable(tinycolor(this.focusColour), textColours).toRgbString();
                return;
            case "listItem":
                this.mainColour = lightDark === "light" ? "#ffffff" : "#333333";
                this.hoverColour = colour.toRgbString();//default = 10
                this.activeColour = colour.darken().toRgbString();//default = 10
                this.focusColour = this.hoverColour;

                var tc = mostReadable(tinycolor(this.mainColour), textColours);
                this.textColour = (lightDark === "light" ? tc.lighten() : tc.darken()).toRgbString();
                this.textHoverColour = mostReadable(tinycolor(this.hoverColour), textColours).toRgbString();
                this.textActiveColour = mostReadable(tinycolor(this.activeColour), textColours).toRgbString();
                this.textFocusColour = mostReadable(tinycolor(this.focusColour), textColours).toRgbString();
                return;
            case "sidebar":
                this.mainColour = lightDark === "light" ? "#f7f7f7" : "#222222";
                this.hoverColour = colour.toRgbString();//default = 10
                this.activeColour = colour.darken().toRgbString();//default = 10
                this.focusColour = this.hoverColour;

                this.textColour = colour.toRgbString();
                this.textHoverColour = mostReadable(tinycolor(this.hoverColour), textColours).toRgbString();
                this.textActiveColour = mostReadable(tinycolor(this.activeColour), textColours).toRgbString();
                this.textFocusColour = mostReadable(tinycolor(this.focusColour), textColours).toRgbString();
                return;
            case "node":
                this.mainColour = colour.toRgbString();
                this.hoverColour = colour.toRgbString();//default = 10
                this.activeColour = colour.toRgbString();//default = 10
                this.focusColour = colour.toRgbString();

                this.textColour = mostReadable(tinycolor(this.mainColour), textColours).toRgbString();
                this.textHoverColour = mostReadable(tinycolor(this.hoverColour), textColours).toRgbString();
                this.textActiveColour = mostReadable(tinycolor(this.activeColour), textColours).toRgbString();
                this.textFocusColour = mostReadable(tinycolor(this.focusColour), textColours).toRgbString();
                return;
            case "border":
                this.mainColour = colour.toRgbString();
                this.hoverColour = colour.saturate(20).toRgbString();
                this.activeColour = colour.brighten(20).toRgbString();
                this.focusColour = this.hoverColour;
                break;
            case "background":
                this.mainColour = colour.toRgbString();
                this.hoverColour = colour.saturate(5).toRgbString();
                this.activeColour = colour.brighten(5).toRgbString();
                this.focusColour = this.hoverColour;
                break;
            case "inverted":
                this.mainColour = colour.toRgbString();
                this.hoverColour = lightDark === "light" ? "#ffffff" : "#444444";
                this.activeColour = lightDark === "light" ? "#eeeeee" : "#222222";
                this.focusColour = this.hoverColour;

                this.textColour = mostReadable(colour, textColours).toRgbString();
                this.textHoverColour = colour.toRgbString();
                this.textActiveColour = colour.darken().toRgbString();
                this.textFocusColour = this.hoverColour;
                return;
        }

        this.textColour = mostReadable(colour, textColours).toRgbString();
        this.textHoverColour = mostReadable(tinycolor(this.hoverColour), textColours).toRgbString();
        this.textActiveColour = mostReadable(tinycolor(this.activeColour), textColours).toRgbString();
        this.textFocusColour = mostReadable(tinycolor(this.focusColour), textColours).toRgbString();
    }
    private __type: DynamicColourType;
    private mainColour: string;
    private hoverColour: string;
    private activeColour: string;
    private focusColour: string;

    private textColour: string;
    private textHoverColour: string;
    private textActiveColour: string;
    private textFocusColour: string;

    private _tinyPrimaryColour: string;
    get TinyColour() {
        return tinycolor(this._tinyPrimaryColour);
    }

    get type() {
        return this.__type;
    };
    get $() {
        return this.mainColour;
    }
    get hover() {
        return this.hoverColour;
    }

    get active() {
        return this.activeColour;
    }

    get focus() {
        return this.focusColour;
    }

    get text() {
        return this.textColour;
    }

    get textHover() {
        return this.textHoverColour;
    }

    get textActive() {
        return this.textActiveColour;
    }

    get textFocus() {
        return this.textFocusColour;
    }

    toString(): string {
        return this.mainColour;
    }

    toJss(extras?: { $?: any, hover?: any, active?: any, focus?: any }) {
        return {
            color: this.textColour,
            background: this.mainColour,
            ...(ifExtras(extras, "$")),

            "&:hover": {
                color: this.textHoverColour,
                background: this.hoverColour,
                ...(ifExtras(extras, "hover"))
            },
            "&:active": {
                color: this.textActiveColour,
                background: this.activeColour,
                ...(ifExtras(extras, "active"))
            },
            "&:focus": {
                color: this.textFocusColour,
                background: this.focusColour,
                ...(ifExtras(extras, "focus"))
            }
        }
    }
}

export type ColourMethod = "triad" | "tetrad" | "monochromatic" | "splitcomplement" | "analogous";

export class PrecompiledColourRange {
    constructor(accent: TinyColor, lightDark: "light" | "dark" = "light") {
        this.button = new DynamicColour(accent, "button", lightDark);
        this.inverted = new DynamicColour(accent, "inverted", lightDark);
        this.background = new DynamicColour(accent, "background", lightDark);
        this.border = new DynamicColour(accent, "border", lightDark);
        this.sidebar = new DynamicColour(accent, "sidebar", lightDark);
        this.node = new DynamicColour(accent, "node", lightDark);
        this.listItem = new DynamicColour(accent, "listItem", lightDark);
    }

    button: DynamicColour;
    inverted: DynamicColour;
    background: DynamicColour;
    border: DynamicColour;
    sidebar: DynamicColour;
    node: DynamicColour;
    listItem: DynamicColour;

}

export class Theme {
    constructor(_accent: TinyColor, method: ColourMethod = "triad", lightDark: "light" | "dark" = "light") {
        const accent = _accent;
        const complimentingColours = accent[method]();
        this.accent = new PrecompiledColourRange(accent, lightDark);
        this.secondary = new PrecompiledColourRange(complimentingColours[1], lightDark);
        this.tertiary = new PrecompiledColourRange(complimentingColours[2], lightDark);
    }
    accent: PrecompiledColourRange;
    secondary: PrecompiledColourRange;
    tertiary: PrecompiledColourRange;

    logo: {
        color: string;
        animations: {
            front: string,
            back?: string
        }
    };
    code: 'light' | 'dark';
    misc: {
        font?: string;
        separatorColor: string;

        [name: string]: string;
    };
    graph: {
        background: string;
        pattern: string;
        text?: string;
        nodeHover?: string;
        nodeSelected?: string;
        nodeBackground?: string;
        debug?: string;
        breakpoint?: string;
        edgeSelected?: string;
        trueEdge: {
            default: string;
            selected: string;
        };
        falseEdge: {
            default: string;
            selected: string;
        };


        graphControls: {
            background?: string;
            text?: string;
        }
    };
    text: {
        [name: string]: HoverableStyle;
    };
    background: {
        [name: string]: HoverableStyle
    };
    shadow: {
        debugRun: HoverableStyle;
        debugStop: HoverableStyle;
        flowOverlay: HoverableStyle;
        tooltip: HoverableStyle;
        variable: HoverableStyle;

        default: HoverableStyle;

        button: HoverableStyle;
        inverted: HoverableStyle;

        input: HoverableStyle;
        inputOnWhite: HoverableStyle;
        inputOnBlack: HoverableStyle;

        success: HoverableStyle;
        error: HoverableStyle;

        [name: string]: HoverableStyle;
    };
    gradient?: {
        blue: HoverableStyle;
        green: HoverableStyle;
        red: HoverableStyle; //TODO: Dynamic Gradients

        [name: string]: HoverableStyle;
    };
    transition: {
        quick: string;
        default: string;
        long?: string; //Remove "?" When used anywhere

        [name: string]: string;
    };
    debugPanel: {
        valuePanel: {
            backgroundColor: string;
            borderColor: string;
            headingBackground: string;
            headingColor: string;
        }
    };
}

export type ThemeOption = "light" | "dark";

type GetThemeFunction = (accent: string, method?: ColourMethod) => Theme;

var themes: { light: GetThemeFunction, dark: GetThemeFunction } = {
    dark: function (accentColour, method) {
        var t = new Theme(tinycolor(accentColour), method, "dark");
        t = {
            ...t,
            debugPanel: {
                valuePanel: {
                    backgroundColor: '#222',
                    borderColor: '#444',
                    headingBackground: '#303030',
                    headingColor: '#efefef'
                }
            },
            logo: {
                color: '#ffffff',
                animations: {
                    front: '#ffffff',
                    back: 'transparent'
                }
            },
            code: 'dark',
            misc: {
                separatorColor: '#888',
                separatorOverlayColor: 'transparent'
            },
            graph: {
                background: '#222',
                pattern: '#444',
                nodeBackground: '#333',
                nodeHover: '#fff',
                nodeSelected: '#0469cc',
                debug: 'yellow',
                text: '#ddd',
                edgeSelected: '#ddd',
                falseEdge: {
                    default: '#cc0404',
                    selected: '#db0707'
                },
                trueEdge: {
                    default: '#16c206',
                    selected: '#19db07'
                },
                graphControls: {
                    background: '#111',
                    text: '#ddd',
                }
            },
            text: {
                debugRun: { $: '#49a12b' },
                debugStop: { $: '#c22915' },
                bold: { $: '#bbb' },
                placeholder: { $: '#bbb' },
                label: { $: '#bbb' },
                variable: { $: '#000' },

                flowOverlay: { $: '#ddd' },
                tooltip: { $: '#000' },
                sidebar: { $: '#ddd' },
                sidebarButton: {
                    $: '#ddd',
                    hover: '#ffffff',
                    active: '#ffffff'
                },
                button: {
                    $: '#ddd',
                    hover: '#ffffff',
                    active: '#ffffff'
                },

                primary: {
                    $: '#0469cc',
                    hover: '#111',
                    active: '#222'
                },
                primaryInverted: {
                    $: '#111',
                    hover: '#0469cc',
                    active: '#0056ab'
                },

                input: {
                    $: '#ddd',
                    focus: '#ddd'
                },
                inputOnWhite: {
                    $: '#ddd',
                    focus: '#ddd'
                },
                inputOnBlack: {
                    $: '#ddd'
                },

                default: { $: '#ddd' },
                header: { $: '#bbb' },
                info: { $: 'cyan' },
                warning: { $: 'orange' },
                danger: { $: 'red' },

                disabled: { $: '#ddd', hover: '#ddd', active: '#ddd', focus: '#ddd' },

                tourButton: {
                    $: '#f7f7f7',
                    hover: '#aaa',
                    active: '#0056ab'
                },

                success: { $: 'rgb(9, 224, 77)' },
                error: { $: 'rgb(224, 70, 9)' }
            },
            background: {
                debugRun: { $: 'transparent' },
                debugStop: { $: 'transparent' },

                tooltip: { $: 'rgba(255,255,255,0.75)' },

                flowOverlay: { $: '#333' },

                sidebar: { $: '#111' },
                sidebarButton: {
                    $: '#222',
                    hover: '#13315C',
                    active: '#111'
                },
                sidebarContext: {
                    $: '#181818',
                    hover: '#13315C',
                    active: '#111'
                },
                variable: { $: '#1eff9e' },
                unverifiedVar: { $: '#ffb01e' },
                invalidVar: { $: '#ff431e' },

                input: {
                    $: '#444',
                    focus: '#222'
                },
                inputOnWhite: {
                    $: '#444',
                    focus: '#222'
                },
                inputOnBlack: {
                    $: '#ddd'
                },

                button: {
                    $: '#333',
                    hover: '#0469cc',
                    active: '#0056ab'
                },

                main: { $: '#222' },
                primary: {
                    $: '#111',
                    hover: '#0469cc',
                    active: '#0056ab'
                },
                primaryInverted: {
                    $: '#0469cc',
                    hover: '#333',
                    active: '#000'
                },
                default: { $: '#111', hover: '#555', active: '#666' },
                warning: { $: 'orange' },
                danger: { $: 'red' },
                info: { $: 'cyan' },

                disabled: { $: '#888', hover: '#888', active: '#888', focus: '#888' },

                success: { $: 'rgb(9, 224, 77)' },
                error: { $: 'rgb(224, 70, 9)' },

                tabButton: { $: '#444444' },
                tabList: { $: '#333333' }
            },
            shadow: {
                debugRun: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                debugStop: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                tooltip: { $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)' },
                flowOverlay: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.3)',
                    hover: '0px 2px 12px 0px rgba(0,0,0,0.35)'
                },
                separatorDown: { $: '0px 1.5px 3px 0px rgba(0,0,0,0.18)' },
                separatorUp: { $: '0px -1.5px 3px 0px rgba(0,0,0,0.18)' },
                sidebar: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                variable: { $: '0px 2px 16px 0px rgba(0,0,0,0.1)' },

                numberWheel: { $: 'inset 0px 0px 32px 0px rgba(0,0,0,0.75)' },
                button: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.2)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    active: '0px 2px 16px 1px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(0,0,0,0.4)'
                },
                inverted: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.2)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    active: '0px 2px 16px 1px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(0,0,0,0.4)'
                },
                default: { $: '0px 2px 16px 0px rgba(0,0,0,0.1)' },
                primary: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                },
                primaryInverted: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)',
                    active: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)',
                },
                input: {
                    $: '0px 1px 4px 0px rgba(0,0,0,0.35)',
                    hover: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    active: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    focus: '0px 1px 6px 0px rgba(30, 144, 255, 0.75)'
                },
                inputOnWhite: {
                    $: '0px 1px 4px 0px rgba(0,0,0,0.35)',
                    hover: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    active: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    focus: '0px 1px 6px 0px rgba(30, 144, 255, 0.75)'
                },
                inputOnBlack: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)'
                },
                success: {
                    $: '0px 1px 4px 0px rgba(9, 224, 77,0.7)',
                    hover: '0px 1px 4px 0px rgba(9, 224, 77,0.9)',
                    active: '0px 1px 4px 0px rgba(9, 224, 77,0.9)',
                    focus: '0px 1px 6px 0px rgba(9, 224, 77, 0.5)'
                },
                error: {
                    $: '0px 1px 4px 0px rgba(224, 70, 9,0.7)',
                    hover: '0px 1px 4px 0px rgba(224, 70, 9,0.9)',
                    active: '0px 1px 4px 0px rgba(224, 70, 9,0.9)',
                    focus: '0px 1px 6px 0px rgba(224, 70, 9, 0.5)'
                },
                banner: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.3)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.6)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.8)',
                    focus: '0px 2px 16px 0px rgba(30, 144, 255, 0.8)'
                },
                listItem: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.6)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.7)',
                },
            },
            gradient: {
                blue: {
                    $: 'linear-gradient(to right, rgba(4,193,227,1) 0%, rgba(4,227,171,1) 100%)'
                },
                green: {
                    $: 'linear-gradient(to right, rgba(17,207,0,1) 0%, rgba(33,232,166,1) 100%)'
                },
                red: {
                    $: 'linear-gradient(to right, rgba(230,0,0,1) 0%, rgba(230,34,129,1) 100%)'
                }
            },
            transition: {
                default: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.35s',
                quick: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s',
                long: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.45s',
                hover: 'box-shadow cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, background cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, color cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, font-weight cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s'
            },
        }
        return t;
    },
    light: function (accentColour, method) {
        var t = new Theme(tinycolor(accentColour), method, "light");
        t = {
            ...t,
            debugPanel: {
                valuePanel: {
                    backgroundColor: '#fcfcfc',
                    borderColor: '#e8e8e8',
                    headingBackground: '#efefef',
                    headingColor: '#333',
                }
            },
            logo: {
                color: '#171738',
                animations: {
                    front: '#171738',
                    back: 'transparent'
                }
            },
            misc: {
                separatorColor: '#ccc',
                separatorOverlayColor: '#ccc'
            },
            code: 'light',
            graph: {
                background: '#f7f7f7',
                pattern: 'lightgrey',
                nodeHover: '#ccc',
                debug: 'yellow',
                nodeBackground: '#ffffff',
                //text: '#333333',
                edgeSelected: '#333',
                falseEdge: {
                    default: '#cc0404',
                    selected: '#db0707'
                },
                trueEdge: {
                    default: '#16c206',
                    selected: '#19db07'
                },
                graphControls: {}
            },
            text: {
                debugRun: { $: '#49a12b' },
                debugStop: { $: '#c22915' },
                bold: { $: '#555' },
                placeholder: { $: '#666' },
                label: { $: '#555' },
                variable: { $: '#000' },

                flowOverlay: { $: '#333' },
                tooltip: { $: '#ffffff' },
                sidebar: { $: '#333' },
                sidebarButton: {
                    $: '#333',
                    hover: '#ffffff',
                    active: '#ffffff'
                },
                button: {
                    $: '#333',
                    hover: '#ffffff',
                    active: '#ffffff'
                },

                input: { $: 'inherit' },
                inputOnWhite: { $: 'inherit' },
                inputOnBlack: { $: 'inherit' },

                primary: {
                    $: 'dodgerblue',
                    hover: '#f7f7f7',
                    active: '#ffffff'
                },
                primaryInverted: {
                    $: '#ffffff',
                    hover: 'dodgerblue',
                    active: '#59ACFF'
                },

                default: { $: '#333' },
                header: { $: '#555' },
                info: { $: 'cyan' },
                warning: { $: 'orange' },
                danger: { $: 'red' },

                disabled: { $: '#888', hover: '#888', active: '#888', focus: '#888' },

                tourButton: {
                    $: '#333',
                    hover: '#666',
                    active: 'dodgerblue'
                },

                success: { $: 'rba(52, 235, 79)' },
                error: { $: 'rgb(235, 64, 52)' }
            },
            background: {
                debugRun: { $: 'transparent' },
                debugStop: { $: 'transparent' },
                tooltip: { $: 'rgba(0,0,0,0.75)' },

                flowOverlay: { $: '#ffffff' },

                sidebar: { $: '#ffffff' },
                sidebarButton: {
                    $: '#f7f7f7',
                    hover: '#13315C',
                    active: '#111'
                },
                sidebarContext: {
                    $: '#fafafa',
                    hover: '#13315C',
                    active: '#111'
                },
                variable: { $: '#1eff9e' },
                unverifiedVar: { $: '#ffb01e' },
                invalidVar: { $: '#ff431e' },

                input: {
                    $: '#f7f7f7',
                    focus: '#ffffff'
                },
                inputOnWhite: {
                    $: '#f7f7f7',
                    focus: '#ffffff'
                },
                inputOnBlack: {
                    $: '#333'
                },

                button: {
                    $: '#f7f7f7',
                    hover: 'dodgerblue',
                    active: '#59ACFF'
                },

                main: { $: '#f7f7f7' },
                primary: {
                    $: '#ffffff',
                    hover: 'dodgerblue',
                    active: '#59ACFF'
                },
                primaryInverted: {
                    $: 'dodgerblue',
                    hover: '#f7f7f7',
                    active: '#ffffff'
                },
                default: { $: '#ffffff' },
                warning: { $: 'orange' },
                danger: { $: 'red' },
                info: { $: 'cyan' },

                disabled: { $: '#ddd', hover: '#ddd', active: '#ddd', focus: '#ddd' },

                success: { $: 'rba(52, 235, 79)' },
                error: { $: 'rgb(235, 64, 52)' },

                tabButton: { $: '#ffffff' },
                tabList: { $: '#eeeeee' }
            },
            shadow: {
                debugRun: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                debugStop: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                tooltip: { $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)' },
                flowOverlay: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.3)',
                    hover: '0px 2px 12px 0px rgba(0,0,0,0.35)'
                },
                separatorDown: { $: '0px 1.5px 3px 0px rgba(0,0,0,0.18)' },
                separatorUp: { $: '0px -1.5px 3px 0px rgba(0,0,0,0.18)' },
                sidebar: {
                    $: '0px 2px 8px 0px rgba(19, 49, 92, 0.5)',
                    hover: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)',
                    active: '0px 2px 16px 2px rgba(19, 49, 92, 1)',
                    focus: '0px 2px 16px 0px rgba(19, 49, 92, 0.75)'
                },
                variable: { $: '0px 2px 16px 0px rgba(0,0,0,0.1)' },
                numberWheel: { $: 'inset 0px 0px 16px 0px rgba(0,0,0,0.75)' },
                button: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.2)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    active: '0px 2px 16px 1px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(0,0,0,0.4)'
                },
                inverted: {
                    $: '0px 2px 8px 0px rgba(0,0,0,0.2)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    active: '0px 2px 16px 1px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(0,0,0,0.4)'
                },
                default: { $: '0px 2px 16px 0px rgba(0,0,0,0.1)' },
                primary: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                },
                primaryInverted: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)',
                    active: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)',
                },
                input: {
                    $: '0px 1px 4px 0px rgba(0,0,0,0.35)',
                    hover: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    active: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    focus: '0px 1px 6px 0px rgba(30, 144, 255, 0.75)'
                },
                inputOnWhite: {
                    $: '0px 1px 4px 0px rgba(0,0,0,0.35)',
                    hover: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    active: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                    focus: '0px 1px 6px 0px rgba(30, 144, 255, 0.75)'
                },
                inputOnBlack: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)'
                },
                success: {
                    $: '0px 1px 4px 0px rgba(52, 235, 79,0.9)',
                    hover: '0px 1px 4px 0px rgba(52, 235, 79,1)',
                    active: '0px 1px 4px 0px rgba(52, 235, 79,1)',
                    focus: '0px 1px 6px 0px rgba(52, 235, 79, 0.95)'
                },
                error: {
                    $: '0px 1px 4px 0px rgba(235, 64, 52,0.9)',
                    hover: '0px 1px 4px 0px rgba(235, 64, 52,1)',
                    active: '0px 1px 4px 0px rgba(235, 64, 52,1)',
                    focus: '0px 1px 6px 0px rgba(235, 64, 52, 0.95)'
                },
                banner: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                    focus: '0px 2px 16px 0px rgba(30, 144, 255, 0.4)'
                },
                listItem: {
                    $: '0px 2px 16px 0px rgba(0,0,0,0.1)',
                    hover: '0px 2px 16px 0px rgba(0,0,0,0.2)',
                    active: '0px 2px 16px 0px rgba(0,0,0,0.4)',
                },
            },
            gradient: {
                blue: {
                    $: 'linear-gradient(to right, rgba(4,193,227,1) 0%, rgba(4,227,171,1) 100%)'
                },
                green: {
                    $: 'linear-gradient(to right, rgba(17,207,0,1) 0%, rgba(33,232,166,1) 100%)'
                },
                red: {
                    $: 'linear-gradient(to right, rgba(230,0,0,1) 0%, rgba(230,34,129,1) 100%)'
                }
            },
            transition: {
                default: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.35s',
                quick: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s',
                long: 'all cubic-bezier(0.4, 0.0, 0.2, 1) 0.45s',
                hover: 'box-shadow cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, background cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, color cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s, font-weight cubic-bezier(0.4, 0.0, 0.2, 1) 0.25s'
            },

        }
        return t;
    }
};

var ThemeEvents = new EventEmitter();
var setTheme = (newTheme: string, newAccent: string, colourMethod: ColourMethod) => {
    ThemeEvents.emit('change', newTheme, newAccent, colourMethod);
    localStorage.setItem('theme', newTheme);
    localStorage.setItem('accent', newAccent);
    localStorage.setItem('colourMethod', colourMethod);
}

export {
    setTheme,
    themes,
    ThemeEvents
};