import React from 'reactn';
import Humanizer from './helpers/Humanizer';

interface PageNameManagerProps {
    location?: string;
}

class PageNameManager extends React.Component<PageNameManagerProps> {
    render() {
        var { location } = this.props;

        if(location.startsWith('/flow') && !location.startsWith('/flowList')) {
            if(location === "/flow/example") {
                location = "Flow Example";
            } else if(location === "/flow") {
                location = "New Flow";
            } else {
                location = "Edit Flow";
            }
        } else if (location.startsWith('/settings/')) {
            location = location.split('/').reverse().join(' ')
        }

        document.getElementsByTagName("title")[0].innerHTML = `Flow Builder - ${Humanizer.capitalizeAll(Humanizer.camelcaseToHuman(location.split('/').join(' ').trim()))}`;

        return null;

        // return (
        //     <React.Fragment>
        //         <Portal node={document.getElementsByTagName("title")[0]}>
        //             {
        //                 ` - ${Humanizer.capitalizeAll(Humanizer.camelcaseToHuman(location.split('/').join(' ').trim()))}`
        //             }
        //         </Portal>
        //     </React.Fragment>
        // );
    }
}

export default PageNameManager;